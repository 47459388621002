import { RichText } from 'prismic-reactjs';
import React from 'react';
import { generateImageProps } from '../../helpers/image';

import { MaxWidth } from '../../components/MaxWidth';

import { ContactDetails } from './details.js';
import { ContactForm } from './form.js';

const HeroContact = ({ slice, index }) => {
  const Heading = index === 0 ? 'h1' : 'h2';

  return (
    <div className="contact">
      <div className="contact__gradient" />

      {slice.primary.background?.url ? (
        <div className="contact__background">
          <img
            {...generateImageProps(slice.primary.background, {
              width: 1200,
              sizes: '600px',
            })}
            draggable={false}
          />
        </div>
      ) : null}

      <MaxWidth>
        <div className="contact__container">
          <div className="contact__top">
            <div className="contact__top__title">
              <Heading>{RichText.asText(slice.primary.title)}</Heading>
            </div>
          </div>

          <div className="contact__bottom">
            <ContactForm slice={slice} />

            <ContactDetails slice={slice} />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default HeroContact;
