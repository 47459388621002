import React from 'react';

import { Title } from '../../components/Title';
import { Stars } from '../../components/ReviewStars';
import { MaxWidth } from '../../components/MaxWidth';

import { PrismicRichText } from '@prismicio/react';

import classnames from 'classnames';

const Review = ({ slice }) => {
  switch (slice.primary.theme) {
    case 'light':
      slice.primary.theme = 'light';
      break;
    case 'dark':
      slice.primary.theme = 'dark';
      break;
    default:
      slice.primary.theme = 'light';
      break;
  }

  return (
    <div
      className={classnames('review', `review--theme-${slice.primary.theme}`)}
    >
      <MaxWidth>
        <div className="review__container">
          <div className="review__title">
            {slice.primary.title ? (
              <Title borderTop align="center">
                <PrismicRichText field={slice.primary.title} />
              </Title>
            ) : null}
          </div>
          <div className="review__content">
            <div className="review__item">
              {slice.items.map((item, index) => (
                <div className="review__item__container" key={index}>
                  {item.text ? (
                    <div className="review__item__text">
                      <PrismicRichText field={item.text} />
                    </div>
                  ) : null}
                  <div className="review__item__review">
                    {item.nbrStars ? (
                      <div className="review__item__stars">
                        <Stars color="blue" difficulty={item.nbrStars} />
                      </div>
                    ) : null}
                    {item.name ? (
                      <div className="review__item__name">
                        <PrismicRichText field={item.name} />
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Review;
