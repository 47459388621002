import React from 'react';
import classnames from 'classnames';

import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';
import { Link } from '../../components/Link';

import * as prismicH from '@prismicio/helpers';
import { generateImageProps } from '../../helpers/image';

const MoreEscapeGame = ({ slice }) => {
  switch (slice.primary.theme) {
    case 'light':
      slice.primary.theme = 'light';
      break;
    case 'dark':
      slice.primary.theme = 'dark';
      break;
    default:
      slice.primary.theme = 'dark';
      break;
  }
  return (
    <div
      className={classnames(
        'more-escape-game',
        `more-escape-game--${slice.primary.theme}`
      )}
    >
      <MaxWidth>
        <div className="more-escape-game__container">
          <div className="more-escape-game__content">
            <div className="more-escape-game__title">
              <Title
                borderTop
                align="center"
                titleUpperCase
                color={slice.primary.color}
                subTitle={<PrismicRichText field={slice.primary.subTitle} />}
                subTitleUpperCase
              >
                <PrismicRichText field={slice.primary.title} />
              </Title>
            </div>
            {prismicH.asText(slice.primary.description) && (
              <div className="more-escape-game__description">
                <PrismicRichText field={slice.primary.description} />
              </div>
            )}
            <div className="more-escape-game__item__content">
              {slice?.items?.map((item, index) => (
                <div className="more-escape-game__item" key={index}>
                  <div className="more-escape-game__item__image">
                    <div className="more-escape-game__item__image__overlay">
                      {item.ImageOverlay ? (
                        <img
                          {...generateImageProps(item.ImageOverlay, {
                            width: 1000,
                            sizes: '1200px',
                          })}
                        />
                      ) : null}
                    </div>
                    {item.imageLink ? (
                      <Link field={item.imageLink}>
                        <img
                          {...generateImageProps(item.image, {
                            width: 1000,
                            sizes: '1200px',
                          })}
                        />
                      </Link>
                    ) : null}
                  </div>
                  {item.tags ? (
                    <div
                      className={classnames(
                        'more-escape-game__tags',
                        item.color
                          ? `more-escape-game__tags--${item.color}`
                          : null
                      )}
                    >
                      <PrismicRichText field={item.tags} />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default MoreEscapeGame;
