import React from 'react';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';

import { MaxWidth } from '../../components/MaxWidth';

import { generateImageProps, isImage } from '../../helpers/image';

const Iframe = ({ slice }) => {
  const customCode = prismicH.asText(slice.primary.customCode);

  return (
    <div
      className={classNames(
        'iframe',
        slice.primary.width
          ? `iframe--width-${slice.primary.width.toLowerCase()}`
          : null
      )}
      id={slice.primary.anchor || null}
    >
      <MaxWidth>
        {prismicH.asText(slice.primary.title) ? (
          <h2 className="iframe__title">
            {prismicH.asText(slice.primary.title)}
          </h2>
        ) : null}

        {slice.items?.length > 0 ? (
          <ul className="iframe__customers">
            {slice.items.map((item, index) => {
              return isImage(item.customerLogo) ? (
                <li key={index}>
                  <img
                    {...generateImageProps(item.customerLogo, {
                      sizes: '220px',
                      lazy: true,
                    })}
                    draggable="false"
                  />
                </li>
              ) : null;
            })}
          </ul>
        ) : null}

        <div className="iframe__embed">
          {customCode ? (
            <div dangerouslySetInnerHTML={{ __html: customCode }} />
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Iframe;
