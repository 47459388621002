import { PrismicRichText } from '@prismicio/react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { generateImageProps } from '../../helpers/image.js';

import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { MobileSlider } from '../../components/MobileSlider';

const SLIDE_INDEX_ATTRIBUTE_NAME = 'slide-index';

const replaceHeadingWith = (richText, replacement = 'paragraph') => {
  return richText.map((item) => {
    return {
      ...item,
      type:
        typeof item.type === 'string' && item.type.startsWith('heading')
          ? replacement
          : item.type,
    };
  });
};

const Slider = ({ slice }) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isSlidingTimeout, setIsSlidingTimeout] = useState(null);

  const sliderWrapperRef = useRef(null);
  const sliderRef = useRef(null);
  const slidesRef = useRef([]);

  const [slideWidth, setSlideWidth] = useState(0);

  const windowSize = useWindowSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const [textTransitionStyle, setTextTransitionStyle] = useState({});

  useEffect(() => {
    if (slidesRef.current.length > 0)
      setSlideWidth(
        parseInt(getComputedStyle(slidesRef.current[0]).width.replace('px', ''))
      );
    setSliderIndex(0);
  }, [windowSize.width, isMobile]);

  useEffect(() => {
    setSlide(sliderIndex);
  }, [sliderIndex]);

  const setSlide = (index) => {
    if (isSlidingTimeout) clearTimeout(isSlidingTimeout);
    setIsSliding(true);
    setIsSlidingTimeout(setTimeout(() => setIsSliding(false), 800));
    sliderRef.current.scrollTo({
      left: slideWidth * index,
      behavior: 'smooth',
    });

    setTextTransitionStyle({
      transform: `translateX(${index * 270}px)`,
      transition: 'transform 0.5s ease-in-out',
    });
  };

  const handleScroll = (event) => {
    if (isSliding) return;

    const scroll = parseInt(event.currentTarget.scrollLeft) + slideWidth / 2;
    const i = parseInt(scroll / slideWidth);
    if (i !== sliderIndex) setSliderIndex(i);
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const items = [...slice.items];

  return (
    <div className="slider">
      <div className="slider__transition-left">
        <picture>
          <source
            srcSet="/illustrations/transition-paint-left.avif"
            type="image/avif"
          />

          <img
            src="/illustrations/transition-paint-left.png"
            alt="Paint"
            draggable={false}
            width="683px"
            height="217px"
            loading="lazy"
          />
        </picture>
      </div>
      <div className="slider__transition-right">
        <picture>
          <source
            srcSet="/illustrations/transition-paint-right.avif"
            type="image/avif"
          />

          <img
            src="/illustrations/transition-paint-right.png"
            alt="Paint"
            draggable={false}
            width="605px"
            height="216px"
            loading="lazy"
          />
        </picture>
      </div>

      {slice.primary.sectionTitle ? (
        <div className="slider__title">
          <Heading
            ruler={false}
            title={
              slice.primary.sectionTitle ? (
                <PrismicRichText field={slice.primary.sectionTitle} />
              ) : null
            }
          />
        </div>
      ) : null}

      <div className="slider__mobile">
        <MobileSlider items={items} />
      </div>

      <div className="slider__desktop">
        <div className="slider__slide__images">
          <div className="slider__slide__image__scroll">
            <div
              className="slider__slide__images__wrap"
              style={textTransitionStyle}
            >
              {slice.items.map((item, index) => {
                return (
                  <div
                    className={classnames('slider__slide__images__container', {
                      'slider__slide__images__container--active':
                        index === sliderIndex,

                      'slider__slide__images__container--inactive':
                        index !== sliderIndex && index > sliderIndex,

                      'slider__slide__images__container--passed':
                        index < sliderIndex - 1,
                    })}
                    {...{
                      [SLIDE_INDEX_ATTRIBUTE_NAME]: index,
                    }}
                    key={index}
                    onClick={() => setSliderIndex(index)}
                  >
                    {item.image ? (
                      <img
                        {...generateImageProps(item.image, {
                          sizes: '320px',
                          lazy: true,
                        })}
                        draggable="false"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="slider__slide__content">
          <div className="slider__slide__model">
            {slice.items.map((item, index) => {
              return (
                <div key={index} className="slider__slide__model__slide">
                  <div className="slider__slide__model__slide__title">
                    <Heading
                      title={
                        item.title ? (
                          <PrismicRichText
                            field={replaceHeadingWith(item.title, 'heading2')}
                          />
                        ) : null
                      }
                    />
                  </div>
                  <div className="slider__slide__model__slide__content">
                    {item.content ? (
                      <PrismicRichText field={item.content} />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="slider__slide__content__container"
            ref={sliderRef}
            onScroll={handleScroll}
          >
            <div
              className="slider__slide__content__wrap"
              ref={sliderWrapperRef}
            >
              {slice.items.map((item, index) => {
                return (
                  <div
                    className={classnames(
                      'slider__slide__content__container__wrap',
                      {
                        'slider__slide__content__container__wrap--violet':
                          item.color === 'violet',
                        'slider__slide__content__container__wrap--green':
                          item.color === 'green',
                        'slider__slide__content__container__wrap--raspberry':
                          item.color === 'raspberry',
                        'slider__slide__content__container__wrap--blue':
                          item.color === 'blue',
                        'slider__slide__content__container__wrap--yellow':
                          item.color === 'yellow',
                      }
                    )}
                    key={index}
                    ref={(el) => slidesRef.current.push(el)}
                  >
                    <Heading
                      title={
                        item.title ? (
                          <PrismicRichText
                            field={replaceHeadingWith(item.title)}
                          />
                        ) : null
                      }
                    />
                    <div className="slider__slide__content__container__description">
                      {item.content ? (
                        <PrismicRichText field={item.content} />
                      ) : null}
                    </div>
                    <div className="slider__slide__content__container__button">
                      {item.buttonLinkText ? (
                        <Button
                          field={item.buttonLink}
                          theme={
                            item.color == 'violet'
                              ? 'outline-violet'
                              : item.color == 'green'
                              ? 'outline-green'
                              : item.color == 'raspberry'
                              ? 'outline-raspberry'
                              : item.color == 'blue'
                              ? 'outline-blue'
                              : item.color == 'yellow'
                              ? 'outline-yellow'
                              : 'outline-blue'
                          }
                        >
                          {item.buttonLinkText ? (
                            <PrismicRichText field={item.buttonLinkText} />
                          ) : null}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="slider__content__buttons">
            <div className="slider__content__buttons__arrow-left">
              <button
                onClick={() => {
                  setSliderIndex((index) => {
                    if (index === slice.items.length - 1) {
                      return index - slice.items.length + 1;
                    }

                    return index + 1;
                  });
                }}
              >
                <img
                  src="/icons/arrow-left-full.svg"
                  alt="arrow-left"
                  height="30px"
                  width="30px"
                />
              </button>
            </div>
            <div className="slider__content__buttons__arrow-right">
              <button
                onClick={() =>
                  setSliderIndex((index) => {
                    if (index === 0) {
                      return index + slice.items.length - 1;
                    }

                    return index - 1;
                  })
                }
              >
                <img
                  src="/icons/arrow-right-full.svg"
                  alt="arrow-right"
                  height="30px"
                  width="30px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return windowSize;
};

export default Slider;
