import { format } from 'date-fns';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import compareFunc from 'compare-func';
import React, { useState, useEffect, useRef, useMemo } from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { Link } from '../../components/Link';

import { generateImageProps } from '../../helpers/image';

const AllArticles = ({ slice, context, category = null }) => {
  const articles = useMemo(() => {
    const result = context.articles
      .filter((item) => {
        if (category?.id && item?.data?.category?.id !== category?.id) {
          return false;
        }

        return (
          item?.data?.index !== false || process.env.NODE_ENV === 'development'
        );
      })
      .map((item) => {
        return {
          ...item,
          sortBy: new Date(item.data.publishDate).getTime(),
        };
      })
      .sort(compareFunc('sortBy'))
      .reverse();

    if (slice.primary.articleIndex && slice.primary.articleIndex.uid) {
      result.forEach((element) => {
        if (
          element.uid.toString() === slice.primary.articleIndex.uid.toString()
        ) {
          const indexArticle = result.splice(result.indexOf(element), 1)[0];
          result.unshift(indexArticle);
        }
      });
    }

    return result;
  }, [context.articles, slice.primary.indexArticle]);

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    const publishDate = new Date(articles[0]?.data.publishDate);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - publishDate.getTime();
    const diffDays = timeDiff / (1000 * 3600 * 24);
    const currentItemIsNew = diffDays < 3;

    if (currentItemIsNew !== isNew) {
      setIsNew(currentItemIsNew);
    }
  }, [isNew]);

  return (
    <div className="all-articles">
      <MaxWidth>
        <div className="all-articles__container">
          {category?.data?.name ? (
            <div className="all-articles__container__title">
              <h2>{category?.data?.name}</h2>
            </div>
          ) : null}

          {slice.primary.title ? (
            <div className="all-articles__container__title">
              <h2>{prismicH.asText(slice.primary.title)}</h2>
            </div>
          ) : null}

          <div className="all-articles__list">
            {articles.map((article, index) => {
              const item = article.data;

              const date = new Date(item.publishDate);
              const formattedDate = format(date, 'dd.MM.yy');

              const foundCategory = context.categories.find((item) => {
                return item.id === article?.data?.category?.id;
              });

              return (
                <div
                  className={classnames('all-articles__item', {
                    'all-articles__item--first': index === 0,
                  })}
                  key={index}
                >
                  <div className="all-articles__item__image">
                    <img
                      {...generateImageProps(item.image, {
                        width: 900,
                        sizes: '300px',
                      })}
                      alt={item.image.alt ?? ''}
                    />
                  </div>
                  <div className="all-articles__item__content">
                    <div className="all-articles__item__date">
                      <p>
                        {index === 0 && isNew && !foundCategory?.data?.name
                          ? 'Nouveau'
                          : formattedDate}{' '}
                        {foundCategory?.data?.name
                          ? ` - ${foundCategory?.data?.name}`
                          : null}
                      </p>
                    </div>

                    <div className="all-articles__item__title">
                      {item.title ? (
                        <Link
                          field={{
                            id: article.id,
                            type: article.type,
                            lang: article.lang,
                            uid: article.uid,
                            link_type: 'Document',
                            isBroken: false,
                          }}
                        >
                          <PrismicRichText field={item.title} />
                        </Link>
                      ) : null}
                    </div>
                    <div className="all-articles__item__description">
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default AllArticles;
