import React from "react";
import { PrismicRichText } from "@prismicio/react";

import { Title } from "../../components/Title";
import { MaxWidth } from "../../components/MaxWidth";

import { ClockIcon } from "../../components/Icons";

const HourInfo = ({ slice }) => {
  return (
    <div className="hour-info">
      <MaxWidth>
        <div className="hour-info__container">
          <div className="hour-info__item">
            <Title
              size="medium"
              borderTop
              subTitle={<PrismicRichText field={slice.primary.subtitle} />}
            >
              <PrismicRichText field={slice.primary.title} />
            </Title>

            <div className="hour-info__item__text">
              <PrismicRichText field={slice.primary.text} />
            </div>
          </div>
          <div className="hour-info__item">
            <div className="hour-info__item__block__hour">
              <div className="hour-info__item__block__hour__item">
                <ClockIcon />
                <div className="hour-info__item__block__hour__item__title">
                  <PrismicRichText field={slice.primary.rightText} />
                </div>
              </div>
              <div className="hour-info__item__block__hour__item">
                {slice.items.map((item, index) => (
                  <div className="hour-info__item__block__list" key={index}>
                    <div className="hour-info__item__block__list__day">
                      <PrismicRichText field={item.day} />
                    </div>
                    <div className="hour-info__item__block__list__hour">
                      <PrismicRichText field={item.hour} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default HourInfo;
