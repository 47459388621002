import { PrismicRichText } from '@prismicio/react';
import * as EmailValidator from 'email-validator';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import React, { useState, useRef } from 'react';

import { Button } from '../../components/Button';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const ContactForm = ({ slice }) => {
  const formRef = useRef(null);

  const [state, setState] = useState(STATE.IDLE);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      site: 'Batman Escape',
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      message: message,
    };

    Sentry.withScope((scope) => {
      scope.setExtras(data);

      Sentry.captureMessage('Contact form submit');
    });

    fetch(`/api/contact`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          setState(STATE.ERROR);

          Sentry.withScope((scope) => {
            scope.setExtras(data);

            Sentry.captureException(new Error('Contact form submit error'));
          });
        } else {
          setState(STATE.SUCCESS);
        }
      })
      .catch((error) => {
        setState(STATE.ERROR);

        Sentry.withScope((scope) => {
          scope.setExtras(data);

          Sentry.captureException(new Error(error));
        });
      });
  };

  return (
    <div
      className={classnames('contact__form', {
        'contact__form--state-loading': state === STATE.LOADING,
        'contact__form--state-success': state === STATE.SUCCESS,
      })}
    >
      <form onSubmit={handleSubmit} id="contact-form" ref={formRef}>
        <div className="contact__form__split">
          <div className="contact__form__input-container">
            <input
              className="contact__form__input"
              type="text"
              name="firstName"
              autoComplete="given-name"
              placeholder={slice.primary.labelFirstName}
              value={firstName}
              onInput={(event) => setFirstName(event.target.value)}
              readOnly={state === STATE.LOADING}
            />
          </div>

          <div className="contact__form__input-container">
            <input
              className="contact__form__input"
              type="text"
              name="lastName"
              autoComplete="family-name"
              placeholder={slice.primary.labelName}
              value={lastName}
              onInput={(event) => setLastName(event.target.value)}
              readOnly={state === STATE.LOADING}
            />
          </div>
        </div>

        {slice.primary.labelPhone ? (
          <div className="contact__form__split">
            <div className="contact__form__input-container">
              <input
                className="contact__form__input"
                type="email"
                name="email"
                autoComplete="email"
                placeholder={slice.primary.labelMail}
                value={email}
                onInput={(event) => setEmail(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </div>

            <div className="contact__form__input-container">
              <input
                className="contact__form__input"
                type="tel"
                name="phone"
                autoComplete="phone"
                placeholder={slice.primary.labelPhone}
                value={phone}
                onInput={(event) => setPhone(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </div>
          </div>
        ) : (
          <div className="contact__form__input-container">
            <input
              className="contact__form__input"
              type="email"
              name="email"
              autoComplete="email"
              placeholder={slice.primary.labelMail}
              value={email}
              onInput={(event) => setEmail(event.target.value)}
              readOnly={state === STATE.LOADING}
            />
          </div>
        )}

        <div className="contact__form__input-container-textarea">
          <textarea
            className="contact__form__textarea"
            name="message"
            autoComplete="no"
            placeholder={slice.primary.labelMessage}
            value={message}
            onInput={(event) => setMessage(event.target.value)}
            readOnly={state === STATE.LOADING}
          />
        </div>

        <div className="contact__form__cta">
          <Button
            horizontalPadding="large"
            theme="right-arrow-rounded"
            disabled={
              [STATE.LOADING, STATE.SUCCESS].includes(state) ||
              message === '' ||
              email === '' ||
              EmailValidator.validate(email) === false
            }
          >
            {slice.primary.labelSubmit}
          </Button>

          {state === STATE.ERROR ? (
            <div className="contact__form__cta__error">
              <img
                src="/icons/exclamation-mark-circle-white.svg"
                alt="Attention"
                width="323"
                height="323"
                draggable="false"
              />

              <PrismicRichText field={slice.primary.errorMessage} />
            </div>
          ) : null}
        </div>
      </form>

      {state === STATE.SUCCESS ? (
        <div className="contact__form__success">
          <img
            src="/icons/checkmark-circle-blue.svg"
            alt="Valide"
            width="271"
            height="270"
            draggable="false"
          />

          <PrismicRichText field={slice.primary.successMessage} />
        </div>
      ) : null}
    </div>
  );
};
