import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';

import { Button } from '../../components/Button';
import { generateImageProps } from '../../helpers/image.js';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';

const ArticleSelection = ({ slice, slices, context }) => {
  const isLast =
    slices.length > 0 &&
    slices[slices.length - 1].id === slice.id &&
    context.isArticle;

  const articles = context.articles.filter(
    (article) => !context.document || context.document.id !== article.id
  );

  const showPaperBackground = slice.primary.paperBackground;
  const showTitle = slice.primary.showTitle;

  return (
    <div
      id={slice.primary.anchor || null}
      className={classNames(
        'article-selection',
        {
          'article-selection--is-last': isLast,
        },
        { 'article-selection-no-background': !showPaperBackground }
      )}
    >
      {showPaperBackground ? (
        <>
          <div className="article-selection__top__background">
            <img
              src="/backgrounds/article-bottom-background-mobile.webp"
              alt="Illustration"
              loading="lazy"
            />
          </div>

          <div className="article-selection__middle__background" />

          <div className="article-selection__bottom__background">
            <img
              src="/backgrounds/article-top-background-mobile.webp"
              alt="Illustration"
              loading="lazy"
            />
          </div>
        </>
      ) : null}

      <MaxWidth>
        <div className="article-selection__container">
          {showTitle ? (
            <div
              className={classNames('article-selection__title', {
                'article-selection__title--no-title': !prismicH.asText(
                  slice.primary.title
                ),
              })}
            >
              {prismicH.asText(slice.primary.title) ? (
                <PrismicRichText field={slice.primary.title} />
              ) : (
                <Image
                  src="/logos/gotham-gazette.svg"
                  alt="Gotham City Gazette"
                  draggable="false"
                  width={516}
                  height={106}
                  loading="lazy"
                />
              )}
            </div>
          ) : null}
          <div className="article-selection__list">
            {articles.slice(0, 4).map((article, index) => {
              const item = article.data;

              return (
                <div className="article-selection__item" key={index}>
                  <div className="article-selection__item__image">
                    <img
                      {...generateImageProps(item.image, {
                        width: 900,
                        sizes: '300px',
                        lazy: true,
                      })}
                      alt={item.image.alt ?? ''}
                    />
                  </div>
                  <div className="article-selection__item__content">
                    <div
                      className={classNames('article-selection__item__title', {
                        'article-selection__item__title-no-background':
                          !showPaperBackground,
                      })}
                    >
                      {item.title ? (
                        <Link
                          field={{
                            id: article.id,
                            type: article.type,
                            lang: article.lang,
                            uid: article.uid,
                            link_type: 'Document',
                            isBroken: false,
                          }}
                        >
                          <PrismicRichText field={item.title} />
                        </Link>
                      ) : null}
                    </div>
                    <div
                      className={classNames(
                        'article-selection__item__description',
                        {
                          'article-selection__item__description-no-background':
                            !showPaperBackground,
                        }
                      )}
                    >
                      <p>{`${item.description.slice(0, 64)}...`}</p>
                    </div>
                    <div
                      className={classNames('article-selection__item__button', {
                        'article-selection__item__button-no-background':
                          !showPaperBackground,
                      })}
                    >
                      <Button
                        field={{
                          id: article.id,
                          type: article.type,
                          lang: article.lang,
                          uid: article.uid,
                          link_type: 'Document',
                          isBroken: false,
                        }}
                        theme={
                          showPaperBackground ? 'light' : 'transparent-blue'
                        }
                        rightIcon="right-arrow"
                      >
                        {prismicH.asText(slice.primary.articleButtonText)}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {prismicH.asText(slice.primary.buttonText) && (
            <div className="article-selection__bottom">
              <Button field={slice.primary.buttonLink} theme="dark">
                <PrismicRichText field={slice.primary.buttonText} />
              </Button>
            </div>
          )}
        </div>
      </MaxWidth>
    </div>
  );
};

export default ArticleSelection;
