import classnames from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { useRef, useState } from 'react';
import dynamic from 'next/dynamic';

import { MaxWidth } from '../../components/MaxWidth';
import { Button } from '../../components/Button';

import { generateImageProps } from '../../helpers/image';
import { STATIC_VIDEO_MAP } from '../../helpers/static-video';

const DynamicReactPlayer = dynamic(
  () => import('react-player').then((mod) => mod.default),
  {
    loading: () => <p></p>,
    ssr: false,
  }
);

const HeroTitle = ({ slice }) => {
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const staticVideo = useRef(null);
  const player = useRef(null);

  const position = slice.primary.position || 'center';

  const backgroundFullSize = slice.primary.backgroundFullSize || false;

  const staticVideoSrc = STATIC_VIDEO_MAP[slice.primary.videoStatic];

  const youtubeUrl = slice.primary.video?.youtubeUrl;
  const youtubeId =
    youtubeUrl?.match?.(/[?&]v=([^&]+)/)?.[1] ||
    youtubeUrl?.match?.(/youtu\.be\/([^&]+)/)?.[1] ||
    null;

  const hasVideo = staticVideoSrc || youtubeUrl;

  const handleStart = () => {
    if (staticVideo.current) {
      staticVideo.current.currentTime = 0;
    }

    if (player.current) {
      player.current?.seekTo?.(0);

      setIsPlaying(true);
    }

    setIsStarted(true);
    setIsMuted(false);
  };

  const handlePlayPause = () => {
    if (staticVideo.current) {
      if (staticVideo.current.paused) {
        staticVideo.current.play();
      } else {
        staticVideo.current.pause();
      }
    }
  };

  return (
    <div
      className={classnames('hero-title', `hero-title--position-${position}`, {
        'hero-title--full-size': backgroundFullSize,
        'hero-title--has-video': hasVideo,
        'hero-title--video-started': isStarted,
      })}
    >
      <div className="hero-title__background-container">
        <div
          className={classnames(
            backgroundFullSize === true
              ? 'hero-title__background-overlay'
              : 'hero-title__background__gradient'
          )}
        />
        <img
          {...generateImageProps(slice.primary.background, {
            width: 2000,
            sizes: '100vw',
            priority: true,
          })}
          className="hero-title__background"
        />
      </div>

      <MaxWidth>
        <div className="hero-title__content">
          <h1 className="hero-title__title">
            {prismicH.asText(slice.primary.title)}
          </h1>

          <div className="hero-title__description">
            <PrismicRichText field={slice.primary.description} />
          </div>

          <div className="hero-title__buttons">
            <div>
              {prismicH.asText(slice.primary.buttonText) ? (
                <Button
                  field={slice.primary.buttonLink}
                  theme={
                    ['violet', 'green', 'raspberry', 'yellow'].includes(
                      slice.primary.color
                    )
                      ? slice.primary.color
                      : 'primary'
                  }
                >
                  <PrismicRichText field={slice.primary.buttonText} />
                </Button>
              ) : null}

              {prismicH.asText(slice.primary.secondButtonLinkText) ? (
                <Button
                  field={slice.primary.secondButtonLink}
                  theme={'border-white'}
                >
                  <PrismicRichText field={slice.primary.secondButtonLinkText} />
                </Button>
              ) : null}
            </div>
          </div>

          {prismicH.asText(slice.primary.googleReviews) ? (
            <div className="hero-title__google-reviews">
              <img
                src="/icons/google-reviews.svg"
                alt="Avis Google"
                width="316"
                height="74"
              />

              <PrismicRichText field={slice.primary.googleReviews} />
            </div>
          ) : null}
        </div>

        {hasVideo ? (
          <div className="hero-title__video">
            <div className="hero-title__video-overflow">
              <div className="hero-title__player">
                <div className="hero-title__player__wrap">
                  {staticVideoSrc ? (
                    <video
                      ref={staticVideo}
                      className="hero-title__player__embed"
                      src={staticVideoSrc}
                      loop
                      muted={isMuted}
                      autoPlay
                      onClick={handlePlayPause}
                      playsInline
                    />
                  ) : (
                    <DynamicReactPlayer
                      className="hero-title__player__wrap__react-player"
                      ref={player}
                      url={youtubeUrl}
                      playing={isPlaying}
                      loop
                      controls={false}
                      muted={isMuted}
                      width="100%"
                      height="200%"
                      config={{
                        youtube: {
                          preload: true,
                          embedOptions: {
                            host: 'https://www.youtube-nocookie.com',
                          },
                          playerVars: {
                            playsinline: 1,
                            modestbranding: 1,
                            showinfo: 0,
                            loop: 1,
                            playlist: youtubeId,
                          },
                        },
                      }}
                    />
                  )}

                  {!isStarted ? (
                    <button
                      className="hero-title__control"
                      onClick={handleStart}
                      aria-label="Play"
                    >
                      <img
                        src="/icons/play-button.svg"
                        width="144"
                        height="144"
                        alt="Play"
                        draggable={false}
                      />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};

export default HeroTitle;
