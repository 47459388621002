import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { MaxWidth } from "../../components/MaxWidth";
import { generateImageProps } from "../../helpers/image";
import { Title } from "../../components/Title";
import classnames from "classnames";

const MultiImageText = ({ slice }) => {
  return (
    <div className="multi-image-text">
      <MaxWidth>
        <div className="multi-image-text__items">
          {slice.items.map((item, index) => (
            <div
              key={index}
              className={classnames(
                "multi-image-text__container",
                index % 2 !== 0 ? "multi-image-text__container-reverse" : ""
              )}
            >
              <img
                {...generateImageProps(item.image, {
                  width: 500,
                  sizes: "500px",
                })}
                className="multi-image-text__image"
              />
              <div className="multi-image-text__block-text">
                <Title borderTop titleUpperCase>
                  <PrismicRichText field={item.title} />
                </Title>
                <div className="multi-image-text__text">
                  <PrismicRichText field={item.text} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

export default MultiImageText;
