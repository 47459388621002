import { nanoid } from 'nanoid';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import React, { useState } from 'react';

import { RightArrowFullRounded } from '../Icons';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const NewsletterForm = ({
  label,
  placeholder,
  success = null,
  error = null,
  subText,
}) => {
  const [id, setId] = useState(null);

  useIsomorphicLayoutEffect(() => {
    if (id === null) {
      setId(`id_${nanoid()}`);
    }
  }, []);

  const [state, setState] = useState(STATE.IDLE);

  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email) {
      setState(STATE.LOADING);

      const data = {
        email,
      };

      Sentry.withScope((scope) => {
        scope.setExtras(data);

        Sentry.captureMessage('Newsletter form submit');
      });

      fetch('/api/newsletter', {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((result) => {
          if (!result.ok) {
            setState(STATE.ERROR);

            Sentry.withScope((scope) => {
              scope.setExtras(data);

              Sentry.captureException(new Error('Contact form submit error'));
            });
          } else {
            setState(STATE.SUCCESS);
          }
        })
        .catch((error) => {
          setState(STATE.ERROR);

          Sentry.withScope((scope) => {
            scope.setExtras(data);

            Sentry.captureException(new Error(error));
          });
        });
    }
  };

  return (
    <div
      className={classnames('newsletter-form', {
        'newsletter-form--state-loading': state === STATE.LOADING,
      })}
    >
      {[STATE.IDLE, STATE.LOADING, STATE.ERROR].includes(state) ? (
        <form onSubmit={handleSubmit} method="POST">
          <label className="newsletter-form__label" htmlFor={id}>
            {label}
          </label>

          <div className="newsletter-form__input">
            <input
              className="newsletter-form__input__input"
              id={id}
              type="email"
              name="email"
              autoComplete="email"
              required
              placeholder={placeholder}
              value={email}
              onInput={(event) => setEmail(event.target.value)}
              readOnly={state === STATE.LOADING}
            />

            <button
              className="newsletter-form__submit"
              type="submit"
              disabled={state === STATE.LOADING}
            >
              <RightArrowFullRounded />
              <span className="visually-hidden">S'inscrire</span>
            </button>
          </div>

          <p className="newsletter-form__sub-text">{subText}</p>

          {state === STATE.ERROR ? (
            <p className="newsletter-form__message">{error}</p>
          ) : null}
        </form>
      ) : state === STATE.SUCCESS ? (
        <p className="newsletter-form__message newsletter-form__message--success">
          {success}
        </p>
      ) : null}
    </div>
  );
};
