import AllArticles from './AllArticles';
import ArticleContent from './ArticleContent';
import ArticleContentBottom from './ArticleContentBottom';
import ArticleContentTop from './ArticleContentTop';
import ArticleImageBanner from './ArticleImageBanner';
import ArticleLink from './ArticleLink';
import ArticleSelection from './ArticleSelection';
import ArticleSingle from './ArticleSingle';
import Booking from './Booking';
import CallToAction from './CallToAction';
import ChooseBatman from './ChooseBatman';
import CompactConvenient from './CompactConvenient';
import Experiences from './Experiences';
import Faq from './Faq';
import FindUs from './FindUs';
import GoldTicket from './GoldTicket';
import Hero from './Hero';
import HeroArticle from './HeroArticle';
import HeroContact from './HeroContact';
import HeroMissions from './HeroMissions';
import HeroTitle from './HeroTitle';
import History from './History';
import HourInfo from './HourInfo';
import Iframe from './Iframe';
import ImageText from './ImageText';
import InfiniteSlider from './InfiniteSlider';
import LegalMention from './LegalMention';
import ListCategory from './ListCategory';
import ListItsCategory from './ListItsCategory';
import Menu from './Menu';
import MissionContent from './MissionContent';
import MissionDescription from './MissionDescription';
import MissionGallerie from './MissionGallerie';
import MissionReservation from './MissionReservation';
import MissionVideo from './MissionVideo';
import MoreEscapeGame from './MoreEscapeGame';
import MultiImageText from './MultiImageText';
import MultipleIcons from './MultipleIcons';
import Newsletter from './Newsletter';
import NotFound from './NotFound';
import PresseEcho from './PresseEcho';
import PresseGallery from './PresseGallery';
import PresseHero from './PresseHero';
import PresseSupDoc from './PresseSupDoc';
import Prices from './Prices';
import ProlongedExperience from './ProlongedExperience';
import Quotation from './Quotation';
import Review from './Review';
import SharedSlices from './SharedSlices';
import SharingMoments from './SharingMoments';
import Slider from './Slider';
import Spacer from './Spacer';
import TwoColumns from './TwoColumns';
import Video from './Video';
import VideoText from './VideoText';
import WhyUs from './WhyUs';

export {
  AllArticles,
  ArticleContent,
  ArticleContentBottom,
  ArticleContentTop,
  ArticleImageBanner,
  ArticleLink,
  ArticleSelection,
  ArticleSingle,
  Booking,
  CallToAction,
  ChooseBatman,
  CompactConvenient,
  Experiences,
  Faq,
  FindUs,
  GoldTicket,
  Hero,
  HeroArticle,
  HeroContact,
  HeroMissions,
  HeroTitle,
  History,
  HourInfo,
  Iframe,
  ImageText,
  InfiniteSlider,
  LegalMention,
  ListCategory,
  ListItsCategory,
  Menu,
  MissionContent,
  MissionDescription,
  MissionGallerie,
  MissionReservation,
  MissionVideo,
  MoreEscapeGame,
  MultiImageText,
  MultipleIcons,
  Newsletter,
  NotFound,
  PresseEcho,
  PresseGallery,
  PresseHero,
  PresseSupDoc,
  Prices,
  ProlongedExperience,
  Quotation,
  Review,
  SharedSlices,
  SharingMoments,
  Slider,
  Spacer,
  Video,
  VideoText,
  WhyUs,
};

export const components = {
  all_articles: AllArticles,
  article_content_bottom: ArticleContentBottom,
  article_content_top: ArticleContentTop,
  article_content: ArticleContent,
  article_image_banner: ArticleImageBanner,
  article_link: ArticleLink,
  article_selection: ArticleSelection,
  article_single: ArticleSingle,
  booking: Booking,
  call_to_action: CallToAction,
  choose_batman: ChooseBatman,
  compact_convenient: CompactConvenient,
  experiences: Experiences,
  faq: Faq,
  find_us: FindUs,
  gold_ticket: GoldTicket,
  hero_article: HeroArticle,
  hero_contact: HeroContact,
  hero_missions: HeroMissions,
  hero_title: HeroTitle,
  hero: Hero,
  history: History,
  hour_info: HourInfo,
  iframe: Iframe,
  image_text: ImageText,
  infinite_slider: InfiniteSlider,
  legal_mention: LegalMention,
  list_articles_category: ListItsCategory,
  list_category: ListCategory,
  list_its_category: ListItsCategory,
  menu: Menu,
  mission_content: MissionContent,
  mission_description: MissionDescription,
  mission_gallerie: MissionGallerie,
  mission_video: MissionVideo,
  more_escape_game: MoreEscapeGame,
  multi_image_text: MultiImageText,
  multiple_icons: MultipleIcons,
  newsletter: Newsletter,
  not_found: NotFound,
  presse_echo: PresseEcho,
  presse_gallery: PresseGallery,
  presse_hero: PresseHero,
  presse_sup_doc: PresseSupDoc,
  prices: Prices,
  prolonged_experience: ProlongedExperience,
  quotation: Quotation,
  reservation: MissionReservation,
  review: Review,
  shared_slices: SharedSlices,
  sharing_moments: SharingMoments,
  slider: Slider,
  spacer: Spacer,
  two_columns: TwoColumns,
  video_text: VideoText,
  video: Video,
  why_us: WhyUs,
};
