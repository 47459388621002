import React, { useState, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import * as PrismicH from '@prismicio/helpers';
import classnames from 'classnames';
import { MaxWidth } from '../../components/MaxWidth';

import { BackArrow } from '../../components/Icons';
import { generateImageProps } from '../../helpers/image';

const ProlongedExperience = ({ slice }) => {
  const [active, setActive] = useState(0);
  const historyRef = useRef();

  const handleScroll = () => {
    if (historyRef.current && window) {
      const index = Math.round(
        historyRef.current.scrollLeft / window.innerWidth
      );
      setActive(index);
    }
  };

  const handleDotClick = (index) => {
    setActive(index);
    historyRef.current.scrollTo({
      left: index * window.innerWidth,
      behavior: 'smooth',
    });
  };

  const handleBackClick = () => {
    if (active === 0) {
      const newIndex = slice.items.length - 1;
      setActive(newIndex);
      historyRef.current.scrollTo({
        left: newIndex * window.innerWidth,
        behavior: 'smooth',
      });
    } else {
      setActive(active - 1);
      historyRef.current.scrollTo({
        left: (active - 1) * window.innerWidth,
        behavior: 'smooth',
      });
    }
  };

  const handleNextClick = () => {
    if (active === slice.items.length - 1) {
      setActive(0);
      historyRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    } else {
      setActive(active + 1);
      historyRef.current.scrollTo({
        left: (active + 1) * window.innerWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="prolonged-experience">
      <div className="prolonged-experience__background" />
      <MaxWidth>
        <div className="prolonged-experience__container">
          <div className="prolonged-experience__content">
            <div className="prolonged-experience__title">
              <PrismicRichText field={slice.primary.title} />
            </div>

            <div className="prolonged-experience__menu">
              <div className="prolonged-experience__menu__list__container">
                <ul className="prolonged-experience__menu__list">
                  {slice.items.map((item, index) => (
                    <li
                      className={classnames(
                        'prolonged-experience__menu__list__item',
                        index === active
                          ? 'prolonged-experience__menu__list__item__active'
                          : ''
                      )}
                      key={index}
                    >
                      <button
                        className="prolonged-experience__menu__list__item__back__button"
                        onClick={handleBackClick}
                      >
                        <BackArrow />
                      </button>
                      <button
                        className="prolonged-experience__menu__list__item__button"
                        onClick={() => handleDotClick(index)}
                      >
                        {PrismicH.asText(item.buttonTitle)}
                      </button>
                      <button
                        className="prolonged-experience__menu__list__item__next__button"
                        onClick={handleNextClick}
                      >
                        <BackArrow />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="prolonged-experience__list__absolute"
              onScroll={handleScroll}
              ref={historyRef}
            >
              {slice.items.map((item, index) => (
                <div
                  className={classnames(
                    'prolonged-experience__item__absolute',
                    {
                      active: active === index,
                    }
                  )}
                  key={index}
                >
                  <div className="prolonged-experience__option__block">
                    <div className="prolonged-experience__item-title">
                      <PrismicRichText field={item.titleSection} />
                    </div>
                    <div className="prolonged-experience__item-text">
                      <PrismicRichText field={item.sectionText} />
                    </div>
                    <div className="prolonged-experience__item-icon__text">
                      <PrismicRichText field={item.iconText} />
                    </div>
                  </div>
                  <div className="prolonged-experience__item-image">
                    <img
                      {...generateImageProps(item.image, {
                        sizes: '(min-width: 769px) 400px, 85vw',
                      })}
                      className="prolonged-experience__item-image__img"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div
              className="prolonged-experience__list"
              onScroll={handleScroll}
              ref={historyRef}
            >
              {slice.items.map((item, index) => (
                <div
                  className={classnames('prolonged-experience__item', {
                    active: active === index,
                  })}
                  key={index}
                >
                  <div className="prolonged-experience__option__block">
                    <div className="prolonged-experience__item-title">
                      <PrismicRichText field={item.titleSection} />
                    </div>
                    <div className="prolonged-experience__item-text">
                      <PrismicRichText field={item.sectionText} />
                    </div>
                    <div className="prolonged-experience__item-icon__text">
                      <PrismicRichText field={item.iconText} />
                    </div>
                  </div>
                  <div className="prolonged-experience__item-image">
                    <img
                      {...generateImageProps(item.image, {
                        sizes: '(min-width: 769px) 400px, 85vw',
                      })}
                      className="prolonged-experience__item-image__img"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ProlongedExperience;
