import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import { generateImageProps } from '../../helpers/image';

const MultipleIcons = ({ slice }) => {
  const hasBackgroundLeft =
    Object.entries(slice.primary.backgroundLeft).length > 0;
  const hasBackgroundRight =
    Object.entries(slice.primary.backgroundRight).length > 0;
  return (
    <div className="multiple-icons">
      {hasBackgroundLeft && (
        <img
          className="multiple-icons__background-left"
          {...generateImageProps(slice.primary.backgroundLeft, {
            width: 1200,
            sizes: '600px',
          })}
          draggable={false}
        />
      )}
      {hasBackgroundRight && (
        <img
          className="multiple-icons__background-right"
          {...generateImageProps(slice.primary.backgroundRight, {
            width: 1200,
            sizes: '600px',
          })}
          draggable={false}
        />
      )}
      <MaxWidth>
        {slice.items.map((item, index) => (
          <div className="multiple-icons__item" key={index}>
            <img
              src={item.priority_icon?.url || item.icon.url}
              alt={item.priority_icon?.alt || item.icon.alt || ''}
              draggable={false}
            />
            <PrismicRichText field={item.title} />
          </div>
        ))}
      </MaxWidth>
    </div>
  );
};

export default MultipleIcons;
