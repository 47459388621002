import React from 'react';
import { MaxWidth } from '../../components/MaxWidth';
import { PrismicRichText } from '@prismicio/react';
import { Link } from '../../components/Link';

import { generateImageProps } from '../../helpers/image';

const ArticleLink = ({ slice, context }) => {
  const articles = context.articles;

  const article = articles.find((item) => {
    return item.id === slice.primary.article.id;
  });

  return (
    <div className="article-link">
      <MaxWidth>
        <div className="article-link__container">
          <div className="article-link__sup-title">
            {article ? (
              <PrismicRichText field={slice.primary.suptitle} />
            ) : null}

            <div className="article-link__line" />
          </div>

          <div className="article-link__content">
            {article ? (
              <img
                className="article-link__image"
                {...generateImageProps(article.data.image, {
                  width: 300,
                  sizes: '300px',
                  lazy: true,
                })}
              />
            ) : null}

            <Link className="article-link__link" field={slice.primary.article}>
              {article ? (
                <PrismicRichText field={article.data.title} />
              ) : (
                <PrismicRichText field={slice.primary.suptitle} />
              )}
            </Link>
          </div>

          <div className="article-link__line" />
        </div>
      </MaxWidth>
    </div>
  );
};

export default ArticleLink;
