import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

import { Link } from '../Link';
import { MaxWidth } from '../MaxWidth';
import { NewsletterForm } from '../NewsletterForm';
import classNames from 'classnames';
import Image from 'next/image';

export const FooterSocial = ({ socialProfiles }) => {
  const socialLinks = socialProfiles ? socialProfiles.split('\n') : [];

  const facebook = socialLinks.find((url) => /facebook.com/.test(url));
  const X = socialLinks.find((url) => /x.com/.test(url));
  const instagram = socialLinks.find((url) => /instagram.com/.test(url));
  const tiktok = socialLinks.find((url) => /tiktok.com/.test(url));
  const youtube = socialLinks.find((url) => /youtube.com/.test(url));

  return (
    <ul className="footer__social">
      {facebook && (
        <li>
          <a href={facebook} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/facebook.svg"
              alt="Facebook"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {instagram && (
        <li>
          <a href={instagram} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/instagram.svg"
              alt="Instagram"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {tiktok && (
        <li>
          <a href={tiktok} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/tiktok.svg"
              alt="TikTok"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {youtube && (
        <li>
          <a href={youtube} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/youtube.svg"
              alt="Youtube"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {X && (
        <li>
          <a href={X} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/X.svg"
              alt="X"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}
    </ul>
  );
};

export const Footer = ({ layout, isArticle, displaySkyline }) => {
  return (
    <footer
      className={classNames('footer', {
        'footer--article': isArticle,
      })}
    >
      {displaySkyline ? <div className="footer__skyline" /> : null}

      <div className="footer__wrap">
        <MaxWidth>
          <div className="footer__inner">
            <div className="footer__inner__top">
              <div className="footer__inner__top__left">
                <div className="footer__infos">
                  <Link
                    field={layout.data.logoLink}
                    className="footer__infos__logo"
                  >
                    <img
                      loading="lazy"
                      src={layout.data.logo.url}
                      width={layout.data.logo.dimensions.width}
                      height={layout.data.logo.dimensions.height}
                      alt={layout.data.logo.alt ?? ''}
                    />
                    <span className="visually-hidden">Open menu</span>
                  </Link>

                  <div className="footer__infos__address">
                    <PrismicRichText field={layout.data.adresse} />
                  </div>

                  <FooterSocial socialProfiles={layout.data.socialProfiles} />
                </div>

                <div className="footer__partners">
                  <div className="footer__partners__item">
                    <p>Un concept signé et présenté par</p>
                    <div className="footer__partners__item__images">
                      <Image
                        src="/logos/damadreams.svg"
                        width={146}
                        height={30}
                        alt="damadreams logo"
                      />
                    </div>
                  </div>
                  <div className="footer__partners__item">
                    <p>En association avec DC et Warner Bros. Discovery</p>
                    <div className="footer__partners__item__images">
                      <Image
                        src="/logos/dc.svg"
                        width={42}
                        height={42}
                        alt="dc logo"
                      />
                      <Image
                        src="/logos/warner.svg"
                        width={180}
                        height={53}
                        alt="warner logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer__inner__top__right">
                <NewsletterForm
                  label={prismicH.asText(layout.data.newsletterTitle)}
                  placeholder={prismicH.asText(
                    layout.data.newsletterPlaceholder
                  )}
                  subText={prismicH.asText(layout.data.newsletterSubText)}
                  success={prismicH.asText(layout.data.newsletterSuccess)}
                  error={prismicH.asText(layout.data.newsletterError)}
                />
              </div>
            </div>
            <div className="footer__inner__bottom">
              {layout.data.column.map((item, index) => (
                <div key={index} className="footer__column__item">
                  <div className="footer__column__item__title">
                    <PrismicRichText field={item.title} />
                  </div>
                  <div className="footer__column__item__links">
                    <PrismicRichText field={item.links} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </MaxWidth>
        <div className="footer__bottom">
          <MaxWidth>
            <div className="footer__bottom__container">
              <div className="footer__bottom__copyright">
                <PrismicRichText field={layout.data.copyright} />
              </div>

              <ul className="footer__bottom__links">
                {layout.data.links.map((item) => (
                  <li key={prismicH.asText(item.label)}>
                    <Link field={item.link}>
                      <span>{prismicH.asText(item.label)}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </MaxWidth>
        </div>
      </div>
    </footer>
  );
};
