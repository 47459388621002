import { PrismicRichText } from '@prismicio/react';
import React from 'react';

import { Button } from '../../components/Button';

export const ContactDetails = ({ slice }) => {
  return (
    <div className="contact__details">
      <div className="contact__content">
        <div className="contact__content__container">
          {slice.primary.titleContent1 ? (
            <div className="contact__content__container__title">
              <PrismicRichText field={slice.primary.titleContent1} />
            </div>
          ) : null}
          {slice.primary.content1 ? (
            <div className="contact__content__container__description">
              <PrismicRichText field={slice.primary.content1} />
            </div>
          ) : null}
          {slice.primary.titleContent2 ? (
            <div className="contact__content__container__title">
              <PrismicRichText field={slice.primary.titleContent2} />
            </div>
          ) : null}

          {slice.primary.link && slice.primary.linkText ? (
            <div className="contact__content__container__link">
              <Button
                field={slice.primary.link}
                horizontalPadding="large"
                height="extra-small"
                theme="transparent"
                rightIcon="right-arrow-rounded"
              >
                {slice.primary.linkText ? (
                  <PrismicRichText field={slice.primary.linkText} />
                ) : null}
              </Button>
            </div>
          ) : null}
          {slice.primary.content2 ? (
            <div className="contact__content__container__description">
              <PrismicRichText field={slice.primary.content2} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
