import { PrismicRichText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import classnames from "classnames";
import React from "react";
import { generateImageProps } from "../../helpers/image";
import { MaxWidth } from "../../components/MaxWidth";
import { Button } from "../../components/Button";
import { Title } from "../../components/Title";

const PressHeroTextItem = ({ item }) => {
  return (
    <div className="presshero__text__container__item">
      {prismicH.isFilled.richText(item.name) &&
      prismicH.isFilled.richText(item.phoneNumber) ? (
        <div className="presshero__text__container__item__info">
          <PrismicRichText field={item.name} />
          <span>/</span>
          <PrismicRichText field={item.phoneNumber} />
        </div>
      ) : item.name ? (
        <div>
          <PrismicRichText field={item.name} />
        </div>
      ) : item.phoneNumber ? (
        <div>
          <PrismicRichText field={item.phoneNumber} />
        </div>
      ) : null}
      {item.email ? (
        <div className="presshero__text-item__email">
          <a href={`mailto:${prismicH.asText(item.email)}`}>
            <PrismicRichText field={item.email} />
          </a>
        </div>
      ) : null}
    </div>
  );
};

const PresseHero = ({ slice }) => {
  return (
    <div className="presshero">
      {slice.primary?.image ? (
        <div className="presshero__background">
          <img
            {...generateImageProps(slice.primary.image, {
              width: 1200,
              sizes: "100vw",
            })}
          />
        </div>
      ) : null}
      <MaxWidth>
        <div className="presshero__container">
          <div className="presshero__text">
            <div className="presshero__text__title">
              <Title size="custom">
                <PrismicRichText field={slice.primary.title} />
              </Title>
            </div>
            <div className="presshero__text__container">
              {slice.items.map((item, i) => (
                <PressHeroTextItem item={item} key={i} />
              ))}
            </div>
          </div>
          <div className="presshero__button">
            <Button field={slice.primary.linkButton} theme="border-white">
              {prismicH.asText(slice.primary.buttonText)}
            </Button>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default PresseHero;
