import classnames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import React, { useCallback, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';

import { MaxWidth } from '../../components/MaxWidth';

import { ICONS } from '../../components/Icons';

import 'photoswipe/dist/photoswipe.css';

const DynamicPhotoSwipeGallery = dynamic(
  () => import('react-photoswipe-gallery').then((mod) => mod.Gallery),
  {
    loading: () => <p></p>,
    ssr: false,
  }
);

const DynamicPhotoSwipeItem = dynamic(
  () => import('react-photoswipe-gallery').then((mod) => mod.Item),
  {
    loading: () => <p></p>,
    ssr: false,
  }
);

import {
  augmentDimensions,
  changeImageUrl,
  generateImageProps,
  generateSrcSet,
  isImage,
} from '../../helpers/image';

const SLIDES_OPTIONS = {
  origin: 'center',
  perView: 'auto',
};

const GalleryItem = ({ image, active }) => {
  const dimensions = useMemo(() => {
    return augmentDimensions(image.dimensions);
  }, [image.dimensions]);

  return (
    <DynamicPhotoSwipeItem
      key={image.id}
      original={image.url}
      originalSrcset={generateSrcSet(image.url)}
      thumbnail={changeImageUrl(image.url, 300)}
      width={dimensions?.width ?? undefined}
      height={dimensions?.height ?? undefined}
    >
      {({ ref, open }) => {
        return (
          <img
            ref={ref}
            onClick={active ? open : null}
            className={classnames('keen-slider__slide', {
              'keen-slider__slide--active': active,
            })}
            {...generateImageProps(image, {
              sizes: '(min-width: 769px) 800px, 75vw',
              draggable: 'false',
            })}
          />
        );
      }}
    </DynamicPhotoSwipeItem>
  );
};

const InfiniteSlider = ({ slice, index = -1, heroSideBarHeight = 0 }) => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animated, setAnimated] = useState(false);
  const [dragging, setDragging] = useState(false);

  const slides = useMemo(() => {
    return slice.items.filter((item) => {
      return isImage(item?.images);
    });
  }, [slice.items]);

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: slides.length > 3,
    mode: 'snap',
    rtl: false,
    slides: {
      ...SLIDES_OPTIONS,
      spacing: 48,
    },

    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          ...SLIDES_OPTIONS,
          spacing: 24,
        },
      },
      '(max-width: 768px)': {
        slides: {
          ...SLIDES_OPTIONS,
          spacing: 8,
        },
      },
    },

    created() {
      setLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    animationStarted() {
      setAnimated(true);
    },
    animationStopped() {
      setAnimated(false);
    },
    animationEnded() {
      setAnimated(false);
    },
    dragStarted() {
      setDragging(true);
    },
    dragEnded() {
      setDragging(false);
    },
  });

  const handlePrevious = useCallback(
    (event) => {
      event.stopPropagation();

      if (!animated) {
        instanceRef.current?.prev?.();
      }
    },
    [animated, instanceRef]
  );

  const handleNext = useCallback(
    (event) => {
      event.stopPropagation();

      if (!animated) {
        instanceRef.current?.next?.();
      }
    },
    [animated, instanceRef]
  );

  const onBeforeOpen = (pswpInstance) => {
    pswpInstance.on('change', () => {
      if ((pswpInstance, instanceRef.current)) {
        instanceRef.current?.moveToIdx?.(pswpInstance.currIndex, false, {
          duration: 200,
          easing: (t) => t * (2 - t),
        });
      }
    });
  };

  return (
    <div
      id={slice.primary.anchor || null}
      className={classnames('infinite-slider', {
        'infinite-slider--loaded': loaded,
        'infinite-slider--dragging': dragging,
        'infinite-slider--is-first': index === 0,
      })}
      style={{
        '--hero-sidebar-height':
          heroSideBarHeight > 0 ? `${heroSideBarHeight}px` : heroSideBarHeight,
      }}
    >
      <div ref={sliderRef} className="keen-slider">
        <DynamicPhotoSwipeGallery
          options={{
            mainClass: 'infinite-slider__photo-swipe',
            zoom: false,
            arrowPrevSVG: ICONS.ARROW_LEFT_SLIDER_TEXT,
            arrowNextSVG: ICONS.ARROW_RIGHT_SLIDER_TEXT,
            closeSVG: ICONS.CROSS_TEXT,
          }}
          onBeforeOpen={onBeforeOpen}
        >
          {slides.map((image, index) => {
            const active = index === currentSlide;

            return (
              <GalleryItem
                key={image.id}
                image={image.images}
                active={active}
              />
            );
          })}
        </DynamicPhotoSwipeGallery>
      </div>

      {loaded && instanceRef.current ? (
        <MaxWidth className="infinite-slider__controls">
          <button
            className={
              'infinite-slider__button infinite-slider__button--previous'
            }
            onClick={handlePrevious}
            aria-label="previous slide"
          >
            {ICONS.ARROW_LEFT_SLIDER}
          </button>

          <button
            className={'infinite-slider__button infinite-slider__button--next'}
            onClick={handleNext}
            aria-label="next slide"
          >
            {ICONS.ARROW_RIGHT_SLIDER}
          </button>
        </MaxWidth>
      ) : null}
    </div>
  );
};

export default InfiniteSlider;
