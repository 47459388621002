import React from 'react';
import { MaxWidth } from '../../components/MaxWidth';
import { generateImageProps } from '../../helpers/image';

const HeroArticle = ({ slice, context }) => {
  const Wrapper = slice.primary.asH1 ? 'h1' : 'div';

  return (
    <div className="hero-article">
      <div className="hero-article__background" />
      <MaxWidth>
        <Wrapper>
          {Wrapper === 'h1' ? (
            <span className="visually-hidden">{slice.primary.image?.alt}</span>
          ) : null}

          <img
            className="hero-article__image"
            {...generateImageProps(slice.primary.image, {
              width: 1200,
              sizes: '600px',
            })}
            draggable={false}
          />
        </Wrapper>
      </MaxWidth>
    </div>
  );
};

export default HeroArticle;
