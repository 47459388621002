import React from 'react';

export const ARROW_RIGHT_SLIDER = (
  <svg
    width="13"
    height="19"
    viewBox="0 0 13 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.86133 17.9961L10.2352 9.62221L1.86133 1.24832"
      stroke="black"
      strokeWidth="2.75862"
    />
  </svg>
);

export const ARROW_RIGHT_SLIDER_TEXT = /* html */ `
  <svg
    width="13"
    height="19"
    view-box="0 0 13 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.86133 17.9961L10.2352 9.62221L1.86133 1.24832"
      stroke="black"
      strokeWidth="2.75862"
    />
  </svg>
`;
