import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';

import { generateImageProps } from '../../helpers/image';

import { QuotationForm } from './form';
import * as prismicH from '@prismicio/helpers';

const Quotation = ({ slice }) => {
  return (
    <div className="quotation">
      {slice.primary.background?.url ? (
        <div className="quotation__background">
          <img
            {...generateImageProps(slice.primary.background, {
              width: 1200,
              sizes: '600px',
            })}
            draggable={false}
          />
        </div>
      ) : null}

      <MaxWidth>
        <div className="quotation__container">
          <div className="quotation__top">
            <Title titleUpperCase borderTop>
              <h2>{prismicH.asText(slice.primary.title)}</h2>
            </Title>
          </div>

          <div className="quotation__bottom">
            <QuotationForm slice={slice} />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Quotation;
