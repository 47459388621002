import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';
import { generateImageProps } from '../../helpers/image';

const Reservation = ({ slice }) => {
  return (
    <div className="Reservation">
      <MaxWidth>
        <div className="Reservation__container">
          <div className="Reservation__content">
            <div className="Reservation__title">
              <Title
                borderTop
                align="left"
                color={slice.primary.color}
                subTitle={<PrismicRichText field={slice.primary.subTitle} />}
              >
                <PrismicRichText field={slice.primary.title} />
              </Title>

              <PrismicRichText field={slice.primary.title} />
              <PrismicRichText field={slice.primary.subTitle} />
            </div>
            {slice?.items?.map((item, index) => (
              <div className="Reservation__item" key={index}>
                <div className="Reservation__item__articles">
                  <PrismicRichText field={item.articles} />
                </div>
              </div>
            ))}
            <div className="Reservation__item__button">
              <Button field={slice.primary.ButtonLink}>
                <PrismicRichText field={slice.primary.buttonText} />
              </Button>
            </div>
          </div>
          <div className="Reservation__item__image">
            <div className="Reservation__item__overlay" />
            <img {...generateImageProps(slice.primary.image)} />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Reservation;
