import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';

const TwoColumnsItem = ({ title, content }) => {
  return (
    <div>
      {prismicH.asText(title) ? (
        <Title borderTop titleUpperCase>
          <PrismicRichText field={title} />
        </Title>
      ) : null}

      {prismicH.asText(content) ? (
        <div className="two-columns__content">
          <PrismicRichText field={content} />
        </div>
      ) : null}
    </div>
  );
};

const TwoColumns = ({ slice }) => {
  return (
    <div className="two-columns">
      <MaxWidth>
        <TwoColumnsItem
          title={slice.primary.title1}
          content={slice.primary.content1}
        />

        <TwoColumnsItem
          title={slice.primary.title2}
          content={slice.primary.content2}
        />
      </MaxWidth>
    </div>
  );
};

export default TwoColumns;
