import { PrismicRichText } from '@prismicio/react';
import React from 'react';
import { getSocialIcon } from '../../components/Icons';
import { MaxWidth } from '../../components/MaxWidth';
import { Link } from '../../components/Link';
import { FooterSocial } from '../../components/Footer';

const ArticleContentBottom = ({ slice, context }) => {
  return (
    <div className="article-content-bottom">
      <MaxWidth>
        <div className="article-content-bottom__left">
          <PrismicRichText field={slice.primary.leftContent} />
        </div>
        <div className="article-content-bottom__right">
          <PrismicRichText field={slice.primary.rightContent} />
          <div className="article-content-top__right__share">
            <PrismicRichText field={slice.primary.shareText} />
            <FooterSocial socialProfiles={context.layout.data.socialProfiles} />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ArticleContentBottom;
