import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import classnames from 'classnames';
import { Text } from '../../components/Text';
import { UpArrowRounded, DownArrowFillRounded } from '../../components/Icons';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import AnimateHeight from 'react-animate-height';

const FAQ_INDEX_ATTRIBUTE_NAME = 'faq-index';

const Faq = ({ slice }) => {
  const [faqIndex, setFaqIndex] = useState(null);
  const [isFaqVisible, setFaqVisible] = useState(false);

  const handleClick = (index) => {
    setFaqIndex(index !== faqIndex ? index : null);
  };

  const handleButtonClick = () => {
    setFaqVisible(!isFaqVisible);
  };

  const renderFaqItem = (item, index) => (
    <div className="faq__content__item" key={index}>
      <div className="faq__content__item__header">
        <div className="faq__content__item__header__title">
          <Link
            className="faq__content__item__header__title__link"
            onClick={() => handleClick(index)}
            {...{
              [FAQ_INDEX_ATTRIBUTE_NAME]: index,
            }}
          >
            <PrismicRichText field={item.title} />
          </Link>
        </div>
        <div className="faq__content__item__header__icon">
          {index === faqIndex ? <UpArrowRounded /> : <DownArrowFillRounded />}
        </div>
      </div>
      <AnimateHeight duration={300} height={index === faqIndex ? 'auto' : 0}>
        <div
          className={classnames('faq__content__item__content', {
            'faq__content__item__content--current': index === faqIndex,
          })}
        >
          <div style={{ paddingBottom: '16px' }}>
            <Text>
              <PrismicRichText field={item.content} />
            </Text>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );

  const halfLength = Math.ceil(slice.items.length / 2);

  return (
    <div id={slice.primary.anchor || null} className="faq">
      <MaxWidth>
        <div className="faq__wrap">
          <div className="faq__wrap__text">
            {slice.primary.title && (
              <div className="faq__title">
                <PrismicRichText field={slice.primary.title} />
              </div>
            )}
            {slice.primary.text && (
              <div className="faq__subtitle">
                <PrismicRichText field={slice.primary.text} />
              </div>
            )}

            {slice.primary.displayOption ? (
              <div className="faq__button">
                <Button onClick={handleButtonClick}>
                  <p className="faq__button__text">
                    {isFaqVisible ? 'Fermer la FAQ' : 'Ouvrir la FAQ'}
                  </p>
                </Button>
              </div>
            ) : (
              <div className="faq__content__container faq__content__container--open">
                <div className="faq__content">
                  {slice.items.slice(0, halfLength).map(renderFaqItem)}
                </div>

                <div className="faq__content">
                  {slice.items
                    .slice(halfLength)
                    .map((item, index) =>
                      renderFaqItem(item, index + halfLength)
                    )}
                </div>
              </div>
            )}
          </div>
          <AnimateHeight duration={300} height={isFaqVisible ? 'auto' : 0}>
            <div
              className={classnames('faq__content__container', {
                'faq__content__container--open': isFaqVisible,
              })}
            >
              <div className="faq__content">
                {slice.items.slice(0, halfLength).map(renderFaqItem)}
              </div>

              <div className="faq__content">
                {slice.items
                  .slice(halfLength)
                  .map((item, index) =>
                    renderFaqItem(item, index + halfLength)
                  )}
              </div>
            </div>
          </AnimateHeight>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Faq;
