import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React from 'react';

import { Stars } from '../../components/ReviewStars';
import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';
import { generateImageProps } from '../../helpers/image';

const isGridItemImage = (item) => {
  return item.galerieImages?.url && !item.textGallerie[0];
};

const MissionGallerie = ({ slice, index }) => {
  const useCharacters = slice.primary.useCharacters || false;
  const useGradient = slice.primary.gradient || false;

  const Heading = index === 0 ? 'h1' : 'h2';

  return (
    <div
      className={classnames('mission-gallery', {
        'mission-gallery--use-characters': useCharacters,
        'mission-gallery--use-gradient': useGradient,
      })}
    >
      <MaxWidth>
        {useCharacters && (
          <>
            <img
              className="mission-gallery__left-illustration"
              src="/illustrations/robin.png"
              alt="robin character"
            />
            <img
              className="mission-gallery__right-illustration"
              src="/illustrations/batwoman.png"
              alt="batwoman character"
            />
          </>
        )}
        <div className="mission-gallery__container">
          <div className="mission-gallery__content">
            <div className="mission-gallery__title">
              {prismicH.asText(slice.primary.title) ? (
                <Title
                  size="medium"
                  color={slice.primary.color}
                  borderTop
                  titleUpperCase
                >
                  <Heading
                    dangerouslySetInnerHTML={{
                      __html: prismicH.asText(slice.primary.title),
                    }}
                  />
                </Title>
              ) : null}
            </div>
            {slice?.items?.[2] && (
              <div className="mission-gallery__item__content mission-gallery__item__content__mobile">
                {slice.items[2].isStars ? (
                  <Stars difficulty={slice.items[2].starsNumbers} />
                ) : null}
                <div className="mission-gallery__item__content__testimony">
                  <PrismicRichText field={slice.items[2].textGallerie} />
                </div>
                <div className="mission-gallery__item__content__author">
                  <PrismicRichText field={slice.items[2].textGallerieAuthor} />
                </div>
              </div>
            )}
            <div
              className={classnames('mission-gallery__gallerie__container', {
                'mission-gallery__gallerie__container-characters':
                  useCharacters,
              })}
            >
              <div
                className={classnames(
                  'mission-gallery__gallerie',
                  `mission-gallery__gallerie--text-index-${slice?.items?.findIndex(
                    (item) => {
                      return !isGridItemImage(item);
                    }
                  )}`
                )}
              >
                {slice?.items?.map((item, index) => (
                  <div className="mission-gallery__item" key={index}>
                    {isGridItemImage(item) ? (
                      <div className="mission-gallery__item__image">
                        <img
                          {...generateImageProps(item.galerieImages, {
                            sizes: '272px',
                          })}
                        />
                      </div>
                    ) : (
                      <div className="mission-gallery__item__content">
                        {item.isStars ? (
                          <Stars difficulty={item.starsNumbers} />
                        ) : null}
                        <div className="mission-gallery__item__content__testimony">
                          <PrismicRichText field={item.textGallerie} />
                        </div>
                        <div className="mission-gallery__item__content__author">
                          <PrismicRichText field={item.textGallerieAuthor} />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {slice.primary.buttonGellerieLink.link_type === 'Document' ? (
                <div className="mission-gallery__gallerie__container__button-block">
                  <Button
                    theme="text"
                    rightIcon="down-arrow"
                    field={slice.primary.buttonGellerieLink}
                  >
                    <PrismicRichText field={slice.primary.buttonLinkText} />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default MissionGallerie;
