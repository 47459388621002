import { generateImageProps } from '../../helpers/image';
import { PrismicRichText } from '@prismicio/react';
import { useRouter } from 'next/router';
import * as prismicH from '@prismicio/helpers';
import React from 'react';

import { useGlobalContext } from '../../helpers/context';

import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import { useLazyMapboxMap } from '../../components/MapboxMap/lazy';

const FindUs = ({ slice }) => {
  const { nonDynamicCompatibleHref } = useGlobalContext();
  const router = useRouter();

  const LazyMapboxMap = useLazyMapboxMap({
    fallback: slice.primary.image && (
      <img
        {...generateImageProps(slice.primary.image, {
          width: 1200,
        })}
      />
    ),
    timeout: nonDynamicCompatibleHref.includes(router?.asPath) ? undefined : 0,
  });

  return (
    <div className="find-us">
      <MaxWidth>
        <div className="find-us__left">
          <h2>{prismicH.asText(slice.primary.title)}</h2>
          <div className="find-us__left__map">
            <div className="find-us__left__map__wrap">
              <LazyMapboxMap />
            </div>
          </div>
          <div className="find-us__left__description">
            <PrismicRichText field={slice.primary.mapDescription} />
          </div>
        </div>
        <div className="find-us__right">
          <div className="find-us__right__content">
            <PrismicRichText field={slice.primary.content} />
          </div>
          <div className="find-us__right__button">
            <Button field={slice.primary.buttonLink}>
              {prismicH.asText(slice.primary.buttonText)}
            </Button>
          </div>
          {slice.primary.secondButtonLink &&
          prismicH.asText(slice.primary.secondButtonText) ? (
            <div className="find-us__right__button">
              <Button field={slice.primary.secondButtonLink}>
                {prismicH.asText(slice.primary.secondButtonText)}
              </Button>
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

export default FindUs;
