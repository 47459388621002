import { SliceZone } from '@prismicio/react';
import React from 'react';
import { components } from '..';
import * as prismicH from '@prismicio/helpers';
import { MaxWidth } from '../../components/MaxWidth';
import { Link } from '../../components/Link';

const ArticleContent = ({ context }) => {
  if (!context.document) {
    return null;
  }

  const slices = context.document.data.slices;
  const title = context?.document?.data?.title;

  const category = context.categories.find((item) => {
    return item.id === context.document?.data?.category?.id;
  });

  return (
    <div className="article-content">
      {category?.data?.name ? (
        <ol
          className="article-content__breadcrumb"
          itemScope
          itemType="https://schema.org/BreadcrumbList"
        >
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Link href="/gotham-city-gazette" itemProp="item">
              <span itemProp="name">Gotham City Gazette</span>
            </Link>

            <meta itemProp="position" content="1" />
          </li>

          {' > '}

          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Link
              field={{
                ...category,
                link_type: 'Document',
                isBroken: false,
              }}
              itemProp="item"
            >
              <span itemProp="name"> {category?.data?.name}</span>
            </Link>

            <meta itemProp="position" content="Z" />
          </li>
        </ol>
      ) : null}

      {title && (
        <MaxWidth>
          <h1 className="article-content__title">{prismicH.asText(title)}</h1>
        </MaxWidth>
      )}

      <SliceZone slices={slices} components={components} context={context} />
    </div>
  );
};

export default ArticleContent;
