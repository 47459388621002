import React from 'react';

import { SliceZone } from '@prismicio/react';

import { components } from '../../slices';

const MissionContent = ({ context }) => {
  const mission =
    context.document && context.document.type === 'mission'
      ? context.document
      : null;

  if (!mission) {
    return null;
  }

  return (
    <div className="page-mission">
      <SliceZone slices={mission.data.slices} components={components} />
    </div>
  );
};

export default MissionContent;
