import React from 'react';

import AllArticles from '../AllArticles';

const ListItsCategory = ({ slice, context }) => {
  return (
    <AllArticles slice={slice} context={context} category={context.document} />
  );
};

export default ListItsCategory;
