import React from 'react';
import { PrismicRichText } from '@prismicio/react';

import { Stars } from '../../components/ReviewStars';
import { Title } from '../../components/Title';

import { IconArray } from '../../components/Icons';

import classNames from 'classnames';
import { generateImageProps } from '../../helpers/image';

const MissionDescription = ({ slice }) => {
  return (
    <div className="mission-description">
      <div className="mission-description__wrap">
        <div className="mission-description__background">
          <div className="mission-description__background__gradient" />
          <img {...generateImageProps(slice.primary.backgroundImage)} />
        </div>
        <div className="mission-description__container">
          <div className="mission-description__content">
            <div className="mission-description__main">
              <div className="mission-description__title">
                <Title
                  align="center"
                  color={slice.primary.color}
                  titleUpperCase
                  subTitle={<PrismicRichText field={slice.primary.subTitle} />}
                  subTitleUpperCase
                >
                  <PrismicRichText field={slice.primary.title} />
                </Title>
              </div>
              <div className="mission-description__article">
                <PrismicRichText field={slice.primary.description} />
                <div
                  className={classNames(
                    'mission-description__divider',
                    `mission-description__divider--${slice.primary.color}`
                  )}
                />
                <PrismicRichText field={slice.primary.secondDescription} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mission-description__paragraph">
        <div className="mission-description__paragraph__container">
          <div className="mission-description__list">
            {slice.items.map((item, index) => (
              <div
                className={classNames(
                  'mission-description__item',
                  `mission-description__item-${slice.primary.color}`
                )}
                key={index}
              >
                {item.icon ? (
                  <IconArray icon={item.icon} color={slice.primary.color} />
                ) : null}
                <div className="mission-description__item__content">
                  <div className="mission-description__item__content__container">
                    {item.infoMissionTitle ? (
                      <div className="mission-description__item__title">
                        <PrismicRichText field={item.infoMissionTitle} />
                      </div>
                    ) : null}
                    {item.infoMissionDescription[0] ? (
                      <div className="mission-description__item__description">
                        <PrismicRichText field={item.infoMissionDescription} />
                      </div>
                    ) : null}
                    {item.isStars ? (
                      <Stars
                        color={slice.primary.color}
                        difficulty={item.starsNumbers}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionDescription;
