import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import { useState, useRef } from 'react';

import { generateImageProps } from '../../helpers/image';

import { Title } from '../../components/Title';

import { MaxWidth } from '../../components/MaxWidth';

import isMobile from 'is-mobile';

const History = ({ slice }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const historyRef = useRef();

  const handleScroll = () => {
    if (historyRef.current && window) {
      const index = Math.round(
        historyRef.current.scrollLeft / window.innerWidth
      );
      setActiveSlide(index);
    }
  };

  const handleDotClick = (index) => {
    setActiveSlide(index);
    historyRef.current.scrollTo({
      left: index * window.innerWidth,
      behavior: 'smooth',
    });
  };

  return (
    <div className="history">
      <ul className="history__images">
        {slice.items.map((item, index) => (
          <li
            className={classnames('history__images__item', {
              'history__images__item--active': activeSlide === index,
            })}
            key={index}
          >
            <img
              {...generateImageProps(item.background, {
                width: 900,
                sizes: '300px',
              })}
              alt={item.background.alt ?? ''}
            />
          </li>
        ))}
      </ul>

      <div className="history__container">
        <MaxWidth>
          <div className="history__height">
            {slice.items.map((item, index) => (
              <div className="history__item" key={index}>
                <Title borderTop>
                  <PrismicRichText field={item.title} />
                </Title>
                <div className="history__item__text">
                  <PrismicRichText field={item.text} />
                </div>
              </div>
            ))}
          </div>

          <ul className="history__dots">
            {slice.items.map((item, index) => (
              <button
                key={index}
                className={classnames('history__dots__item', {
                  'history__dots__item--active': activeSlide === index,
                  'history__dots__item--desactivate': slice.items.length === 1,
                })}
                onClick={() => handleDotClick(index)}
              >
                {' '}
                <li key={index} />
              </button>
            ))}
          </ul>

          <ul
            className="history__list"
            onScroll={handleScroll}
            ref={historyRef}
          >
            {slice.items.map((item, index) => (
              <li
                className={classnames('history__item', {
                  'history__item--active': activeSlide === index,
                })}
                key={index}
              >
                <div className="history__item__text__block">
                  <Title titleUpperCase borderTop>
                    <h2>{prismicH.asText(item.title)}</h2>
                  </Title>
                  <div className="history__item__text">
                    <PrismicRichText field={item.text} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </MaxWidth>
      </div>
    </div>
  );
};

export default History;
