import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import * as prismicH from '@prismicio/helpers';
import { NewsletterForm } from '../../components/NewsletterForm';
import Image from 'next/image';

export const NewsletterSocial = ({ socialProfiles }) => {
  const socialLinks = socialProfiles ? socialProfiles.split('\n') : [];

  const facebook = socialLinks.find((url) => /facebook.com/.test(url));
  const X = socialLinks.find((url) => /X.com/.test(url));
  const instagram = socialLinks.find((url) => /instagram.com/.test(url));
  const tiktok = socialLinks.find((url) => /tiktok.com/.test(url));
  const youtube = socialLinks.find((url) => /youtube.com/.test(url));

  return (
    <ul className="newsletter__social">
      {facebook && (
        <li>
          <a href={facebook} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/facebook-blue.svg"
              alt="Facebook"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {instagram && (
        <li>
          <a href={instagram} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/instagram-blue.svg"
              alt="Instagram"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {tiktok && (
        <li>
          <a href={tiktok} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/tiktok.svg"
              alt="TikTok"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {youtube && (
        <li>
          <a href={youtube} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/youtube.svg"
              alt="Youtube"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}

      {X && (
        <li>
          <a href={X} target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="/icons/social/X-blue.svg"
              alt="X"
              draggable={false}
              width="20px"
              height="20px"
            />
          </a>
        </li>
      )}
    </ul>
  );
};

const Newsletter = ({ slice }) => {
  return (
    <div className="newsletter">
      <div className="newsletter__bats">
        <Image
          src="/illustrations/bats.svg"
          alt="Bats"
          draggable={false}
          width={286}
          height={145}
          loading="lazy"
        />
      </div>
      <div className="newsletter__bats--reverse">
        <Image
          src="/illustrations/bats.svg"
          alt="Bats"
          draggable={false}
          width={286}
          height={145}
          loading="lazy"
        />
      </div>
      <MaxWidth>
        <div className="newsletter__content">
          <NewsletterSocial socialProfiles={slice.primary.socialProfiles} />

          {slice.primary.title ? (
            <div className="newsletter__title">
              <PrismicRichText field={slice.primary.title} />
            </div>
          ) : null}
          {slice.primary.description ? (
            <div className="newsletter__description">
              <PrismicRichText field={slice.primary.description} />
            </div>
          ) : null}

          <div className="newsletter__form">
            <NewsletterForm
              placeholder={prismicH.asText(slice.primary.newsletterPlaceholder)}
              success={prismicH.asText(slice.primary.newsletterSuccess)}
              error={prismicH.asText(slice.primary.newsletterError)}
            />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
export default Newsletter;
