import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import isMobile from 'is-mobile';
import React, { useRef, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';

const DynamicReactPlayer = dynamic(
  () => import('react-player').then((mod) => mod.default),
  {
    loading: () => <p></p>,
    ssr: false,
  }
);

const isMobileState = isMobile();

const MissionVideo = ({ slice }) => {
  const [mountEmbed, setMountEmbed] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(!isMobileState);

  const player = useRef(null);

  const handlePlayPause = () => {
    if (player.current) {
      setIsPlaying((current) => !current);
    }
  };

  const handleStart = () => {
    if (player.current) {
      if (isStarted) {
        handlePlayPause();
      } else {
        player.current.seekTo(0);

        setIsMuted(false);
        setIsPlaying(true);

        setIsStarted(true);
      }
    }
  };

  const handleReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);
  const videoUrl = slice.primary.video.embed_url;

  const youtubeIdMatch = videoUrl && videoUrl.match(/[?&]v=([^&]+)/);

  const youtubeId = youtubeIdMatch ? youtubeIdMatch[1] : null;

  return (
    <section
      className={classnames('mission-video', {
        'mission-video--is-mobile': isMobileState,
        'mission-video--ready': isReady,
        'mission-video--playing': isPlaying,
        'mission-video--started': isStarted,
      })}
    >
      <div className="mission-video__player">
        <div className="mission-video__player__wrap">
          {mountEmbed && (
            <DynamicReactPlayer
              className="react-player"
              ref={player}
              url={videoUrl}
              onStart={handleReady}
              playing={isPlaying}
              loop
              controls={false}
              muted={isMuted}
              width="100%"
              height={isMobileState ? '100%' : '202%'}
              config={{
                youtube: {
                  preload: true,
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com',
                  },
                  playerVars: {
                    playsinline: isMobileState ? 0 : 1,
                    modestbranding: 1,
                    showinfo: 0,
                    loop: 1,
                    playlist: youtubeId,
                  },
                },
              }}
            />
          )}

          <div className="mission-video__control">
            <button
              className="mission-video__control__play-pause"
              onClick={handlePlayPause}
              aria-label={isPlaying ? 'Pause' : 'Play'}
              tabIndex={isStarted ? null : -1}
            />
          </div>
        </div>
      </div>

      <div className="mission-video__gradient"></div>
      <div
        className="mission-video__wrapper"
        onClick={isStarted && isPlaying ? handlePlayPause : null}
      >
        <MaxWidth>
          <div className="mission-video__wrap">
            <div className="mission-video__content">
              <Title titleUpperCase borderTop color={slice.primary.color}>
                <PrismicRichText field={slice.primary.title} />
              </Title>
              <PrismicRichText field={slice.primary.description} />

              <div className="mission-video__content__button">
                {slice.primary.buttonLink && slice.primary.buttonTitle ? (
                  <Button
                    field={slice.primary.buttonLink}
                    theme={slice.primary.color}
                  >
                    {slice.primary.buttonTitle ? (
                      <PrismicRichText field={slice.primary.buttonTitle} />
                    ) : null}
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="mission-video__play">
              <button
                onClick={isStarted ? handlePlayPause : handleStart}
                tabIndex={isStarted ? -1 : null}
              >
                <div className="mission-video__play__img">
                  <img
                    src="/icons/play-button.svg"
                    width="144"
                    height="144"
                    alt="Play"
                    draggable={false}
                  />
                </div>
                <div className="mission-button__text">
                  <PrismicRichText field={slice.primary.buttonText} />
                </div>
              </button>
            </div>
          </div>
        </MaxWidth>
      </div>
    </section>
  );
};

export default MissionVideo;
