const WIDTHS = [
  48, 96, 192, 240, 320, 375, 425, 625, 769, 825, 1025, 1280, 1440, 1920, 2560,
  3072,
];

export const FOCUS_POINT = {
  middle: 'center',
  'top-left': 'top left',
  top: 'top',
  'top-right': 'top right',
  right: 'right',
  'bottom-right': 'bottom right',
  bottom: 'bottom',
  'bottom-left': 'bottom left',
  left: 'left',
};

export const DEFAULT_FOCUS_POINT = FOCUS_POINT.middle;

export const isImage = (image) => {
  return image && image.url;
};

export const changeImageUrl = (url, width) => {
  if (/w=\d+&h=\d+/.test(url)) {
    return url.replace(/w=\d+&h=\d+/, `w=${width}`);
  } else if (/\?auto/.test(url)) {
    return `${url}&w=${width}`;
  }

  return url;
};

export const generateSrcSet = (url) => {
  const list = [];

  for (const width of WIDTHS) {
    list.push(`${changeImageUrl(url, width)} ${width}w`);
  }

  return `${list.join(',')}`;
};

/**
 * @param {object} image
 * @param {object} [params={}]
 * @param {number|number[]} [params.width=800]
 * @param {boolean} [params.lazy=false]
 * @param {string} [params.sizes='100vw']
 * @param {boolean} [params.priority=false]
 */
export const generateImageProps = (
  image,
  { width = 800, lazy = false, sizes = '100vw', priority = false } = {}
) => {
  const url = image.url;

  const srcSet = generateSrcSet(url);

  return {
    ...(priority
      ? {
          fetchpriority: 'high',
          loading: 'eager',
          decoding: 'async',
        }
      : lazy
      ? {
          loading: 'lazy',
        }
      : {}),
    src: changeImageUrl(url, width),
    srcSet,
    sizes,
    alt: image.alt ?? '',
    width: image.dimensions ? image.dimensions.width : undefined,
    height: image.dimensions ? image.dimensions.height : undefined,
  };
};

export const augmentDimensions = (dimensions) => {
  if (dimensions.width && dimensions.height && typeof window !== 'undefined') {
    const factor = Math.min(
      Math.max(
        1,
        Math.max(window.screen.width, window.innerWidth) / dimensions.width
      ),
      Math.max(
        1,
        Math.max(window.screen.height, window.innerHeight) / dimensions.height
      )
    );

    return {
      width: dimensions.width * factor,
      height: dimensions.height * factor,
    };
  }

  return dimensions;
};
