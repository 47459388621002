import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { Button } from '../Button';

import { generateImageProps } from '../../helpers/image';

import 'swiper/css';
import 'swiper/css/pagination';

import { Title } from '../Title';

// installer les modules Swiper
SwiperCore.use([Navigation, Pagination]);

const replaceHeadingWith = (richText, replacement = 'paragraph') => {
  return richText.map((item) => {
    return {
      ...item,
      type:
        typeof item.type === 'string' && item.type.startsWith('heading')
          ? replacement
          : item.type,
    };
  });
};

export const MobileSlider = ({ items }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const currentItem = items[currentIndex];

  return (
    <div className="mobile-slider">
      <Title borderTop color={currentItem.color} align="center">
        {currentItem.title ? (
          <PrismicRichText field={replaceHeadingWith(currentItem.title)} />
        ) : null}
      </Title>

      <Swiper
        style={{ width: '100vw', height: 'auto' }}
        slidesPerView={isMobile ? 1.7 : 3}
        spaceBetween={0}
        centeredSlides
        roundLengths
        navigation
        modules={[Pagination]}
        onSlideChange={handleSlideChange}
        className="mobile-slider__swiper"
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              className={classnames('mobile-slider__swiper__item', {
                'mobile-slider__swiper__item--violet': item.color === 'violet',
                'mobile-slider__swiper__item--green': item.color === 'green',
                'mobile-slider__swiper__item--raspberry':
                  item.color === 'raspberry',
              })}
            >
              {item.image && (
                <img
                  {...generateImageProps(item.image, {
                    sizes: '220px',
                    lazy: true,
                  })}
                  draggable="false"
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div
        className={classnames('mobile-slider__content', {
          'mobile-slider__content--violet': currentItem.color === 'violet',
          'mobile-slider__content--green': currentItem.color === 'green',
          'mobile-slider__content--raspberry':
            currentItem.color === 'raspberry',
        })}
      >
        <div className="mobile-slider__content__text">
          {currentItem.content ? (
            <PrismicRichText field={currentItem.content} />
          ) : null}
        </div>
        <div className="mobile-slider__content__button">
          {currentItem.buttonLinkText ? (
            <Button
              theme={
                currentItem.color == 'violet'
                  ? 'outline-violet'
                  : currentItem.color == 'green'
                  ? 'outline-green'
                  : currentItem.color == 'raspberry'
                  ? 'outline-raspberry'
                  : 'outline-violet'
              }
              field={currentItem.buttonLink}
            >
              {currentItem.buttonLinkText ? (
                <PrismicRichText field={currentItem.buttonLinkText} />
              ) : null}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
