import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';

const ListCategory = ({ slice, context }) => {
  return (
    <div className="hero-article">
      <div className="hero-article__background" />
      <MaxWidth>
        <div>
          <ul>
            {context.categories.map((category) => (
              <li key={category.uid}>
                <a href={`/${category.uid}`}>
                  <a>{category.uid}</a>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ListCategory;
