import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/Button';
import { JsonLd } from '../../components/JsonLd';

import { generateImageProps } from '../../helpers/image';

const HeroMissions = ({ slice }) => {
  const theme = slice.primary.theme || 'blue';

  /**
   * @type {import('schema-dts').WithContext<import('schema-dts').Service>}
   */
  const serviceSchema = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    serviceType: 'Escape Game',
    provider: 'Batman Escape Game',
    areaServed: 'Paris',
    availableLanguage: 'fr,en',
    description: slice.primary.description
      ? prismicH.asText(slice.primary.description)
      : '',
  };

  return (
    <div className={`hero-missions hero-missions--theme-${theme}`}>
      <div className="hero-missions__image">
        {slice.primary.image && slice.primary.image.url && (
          <img
            {...generateImageProps(slice.primary.image, {
              width: 1200,
              sizes: '100vw',
              priority: true,
            })}
          />
        )}
      </div>
      <div className="hero-missions__container">
        <div className="hero-missions__content">
          {slice.primary.titleImage && slice.primary.titleImage.url ? (
            <h1>
              <span className="visually-hidden">
                {slice.primary.titleImage?.alt}
              </span>

              <img
                className="hero-missions__logo"
                {...generateImageProps(slice.primary.titleImage, {
                  width: 1047,
                  sizes: '600px',
                  priority: true,
                })}
                draggable="false"
              />
            </h1>
          ) : null}

          {slice.primary.keywords ? (
            <div
              className={classnames('hero-missions__keywords', {
                'hero-missions__keywords--theme-green': theme === 'green',
                'hero-missions__keywords--theme-violet': theme === 'violet',
                'hero-missions__keywords--theme-raspberry':
                  theme === 'raspberry',
              })}
            >
              <PrismicRichText field={slice.primary.keywords} />
            </div>
          ) : null}

          {slice.primary.description ? (
            <div className="image-text__paragraph">
              <PrismicRichText field={slice.primary.description} />
            </div>
          ) : null}

          {prismicH.asText(slice.primary.buttonText) ? (
            <Button
              theme="outline"
              className={classnames(
                'hero-missions__button',
                `hero-missions__button--${theme}`
              )}
            >
              <PrismicRichText field={slice.primary.buttonText} />
            </Button>
          ) : null}
        </div>
      </div>

      {serviceSchema ? (
        <JsonLd id="_json-serviceSchema" schema={serviceSchema} />
      ) : null}
    </div>
  );
};

export default HeroMissions;
