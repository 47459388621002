import { PrismicRichText } from '@prismicio/react';
import { useMediaQuery } from 'react-responsive';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React, { useState, useRef } from 'react';

import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';

import { generateImageProps, isImage } from '../../helpers/image.js';
import { isLink } from '../../helpers/is-link';
import { Head } from 'next/document';

const SLIDE_INDEX_ATTRIBUTE_NAME = 'slide-index';

const Hero = ({ slice }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const scrollRef = useRef(null);

  const [backgroundIndex, setBackgroundIndex] = useState(-1);
  const [scrollBackgroundIndex, setScrollBackgroundIndex] = useState(0);

  const currentBackgroundIndex = isMobile
    ? scrollBackgroundIndex
    : backgroundIndex;

  const handleScroll = () => {
    if (scrollRef.current) {
      const elements = Array.from(
        scrollRef.current.querySelectorAll(`[${SLIDE_INDEX_ATTRIBUTE_NAME}]`)
      );

      for (const element of elements) {
        if (element.getBoundingClientRect().left > 200) {
          const index = parseInt(
            element.getAttribute(SLIDE_INDEX_ATTRIBUTE_NAME),
            10
          );

          setScrollBackgroundIndex(index);

          break;
        }
      }
    }
  };

  const handleMouseOver = (index) => {
    setBackgroundIndex(index);
  };

  const handleMouseOut = () => {
    setBackgroundIndex(-1);
  };

  return (
    <div
      className={classnames('hero', {
        'hero--no-image': !isImage(slice.primary.background),
        'hero--no-slice': (slice.items || []).length === 0,
      })}
    >
      <div className="hero__background">
        <ul className="hero__background__images">
          {isImage(slice.primary.background) ? (
            <li
              className={classnames('hero__background__image', {
                'hero__background__image--active':
                  backgroundIndex === -1 || isMobile,
              })}
            >
              <img
                {...generateImageProps(slice.primary.background, {
                  width: 2000,
                  sizes: '100vw',
                  priority: true,
                })}
              />
            </li>
          ) : null}

          {slice.items.map((item, index) => {
            return (
              <li
                className={classnames('hero__background__image', {
                  'hero__background__image--active':
                    index === currentBackgroundIndex,
                })}
                key={`${slice.id}-${index}`}
                style={isMobile ? { display: 'none' } : null}
              >
                <img
                  {...generateImageProps(item.image, {
                    width: 2000,
                    sizes: '100vw',
                    lazy: true,
                  })}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="hero__gradient" />

      <MaxWidth>
        <div
          className="hero__gradient__violet"
          style={
            currentBackgroundIndex !== 0 || isMobile
              ? { display: 'none' }
              : null
          }
        />

        <div className="hero__container">
          <div className="hero__left">
            <div className="hero__model">
              {slice.items.map((item, index) => {
                return (
                  <div key={index} className="hero__model__container">
                    <div className="hero__model__container__sup-title">
                      {item.supTitleSecondary ? (
                        <PrismicRichText field={item.supTitleSecondary} />
                      ) : null}
                    </div>
                    <div className="hero__model__container__title">
                      {item.supTitleSecondary ? (
                        <PrismicRichText field={item.supTitleSecondary} />
                      ) : null}
                    </div>
                    <div className="hero__model__container__description">
                      {slice.primary.description ? (
                        <PrismicRichText field={slice.primary.description} />
                      ) : null}
                    </div>

                    <div className="hero__model__container__buttons">
                      {slice.primary.primaryLinkText ? (
                        <Button field={slice.primary.primaryLink}>
                          {slice.primary.primaryLinkText ? (
                            <PrismicRichText
                              field={slice.primary.primaryLinkText}
                            />
                          ) : null}
                        </Button>
                      ) : null}

                      {prismicH.asText(slice.primary.secondaryLinkText) ? (
                        <Button
                          field={slice.primary.secondaryLink}
                          theme="outline"
                        >
                          {slice.primary.secondaryLinkText ? (
                            <PrismicRichText
                              field={slice.primary.secondaryLinkText}
                            />
                          ) : null}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="hero__content__container">
              <div className="hero__content__container__wrap">
                <div
                  className={classnames('hero__content', {
                    'hero__content--active': backgroundIndex === -1 || isMobile,
                  })}
                >
                  <h1 className="hero__heading">
                    {prismicH.asText(slice.primary.supTitle) ? (
                      <span className="hero__heading__sup">
                        {prismicH.asText(slice.primary.supTitle)}
                      </span>
                    ) : null}

                    <br />

                    <span
                      dangerouslySetInnerHTML={{
                        __html: prismicH
                          .asHTML(slice.primary.title)
                          ?.replace?.(/<\/h1><h1>/, '<br>')
                          ?.replace(/<\/?h1>/g, ''),
                      }}
                    />
                  </h1>

                  <div className="hero__description">
                    {slice.primary.description ? (
                      <PrismicRichText field={slice.primary.description} />
                    ) : null}
                  </div>

                  <div className="hero__buttons">
                    {slice.primary.primaryLinkText &&
                    isLink(slice.primary.primaryLink) ? (
                      <Button field={slice.primary.primaryLink}>
                        {slice.primary.primaryLinkText ? (
                          <PrismicRichText
                            field={slice.primary.primaryLinkText}
                          />
                        ) : null}
                      </Button>
                    ) : null}

                    {prismicH.asText(slice.primary.secondaryLinkText) &&
                    isLink(slice.primary.secondaryLink) ? (
                      <Button
                        field={slice.primary.secondaryLink}
                        theme="outline"
                      >
                        {slice.primary.secondaryLinkText ? (
                          <PrismicRichText
                            field={slice.primary.secondaryLinkText}
                          />
                        ) : null}
                      </Button>
                    ) : null}
                  </div>
                </div>

                {slice.items.map((item, index) => {
                  return (
                    <div
                      className={classnames(
                        'hero__content__container__wrap__item',
                        {
                          'hero__content__container__wrap__item--active':
                            index === currentBackgroundIndex,
                        }
                      )}
                      style={isMobile ? { display: 'none' } : null}
                      key={index}
                    >
                      <div className="hero__heading">
                        {prismicH.asText(item.supTitleSecondary) ? (
                          <span
                            className={classnames('hero__heading__sup', {
                              'hero__heading__sup--violet':
                                item.cardColor === 'Violet',
                              'hero__heading__sup--raspberry':
                                item.cardColor === 'Framboise',
                              'hero__heading__sup--green':
                                item.cardColor === 'Vert',
                            })}
                          >
                            {prismicH.asText(item.supTitleSecondary)}
                          </span>
                        ) : null}

                        <br />

                        <span
                          dangerouslySetInnerHTML={{
                            __html: prismicH
                              .asHTML(item.titleSecondary)
                              ?.replace?.(/<\/h1><h1>/, '<br>')
                              ?.replace(/<\/?h1>/g, ''),
                          }}
                        />
                      </div>

                      <div className="hero__description">
                        {slice.primary.description ? (
                          <PrismicRichText field={slice.primary.description} />
                        ) : null}
                      </div>

                      <div className="hero__buttons">
                        {slice.primary.primaryLinkText ? (
                          item.cardColor === 'Violet' ? (
                            <Button
                              field={slice.primary.primaryLink}
                              theme="primary-violet"
                            >
                              {slice.primary.primaryLinkText ? (
                                <PrismicRichText
                                  field={slice.primary.primaryLinkText}
                                />
                              ) : null}
                            </Button>
                          ) : item.cardColor === 'Framboise' ? (
                            <Button
                              field={slice.primary.primaryLink}
                              theme="primary-raspberry"
                            >
                              {slice.primary.primaryLinkText ? (
                                <PrismicRichText
                                  field={slice.primary.primaryLinkText}
                                />
                              ) : null}
                            </Button>
                          ) : item.cardColor === 'Vert' ? (
                            <Button
                              field={slice.primary.secondaryLink}
                              theme="primary-green"
                            >
                              {slice.primary.primaryLinkText ? (
                                <PrismicRichText
                                  field={slice.primary.primaryLinkText}
                                />
                              ) : null}
                            </Button>
                          ) : null
                        ) : null}

                        {prismicH.asText(slice.primary.secondaryLinkText) ? (
                          item.cardColor === 'Violet' ? (
                            <Button
                              field={slice.primary.secondaryLink}
                              theme="outline-white-violet"
                            >
                              <PrismicRichText
                                field={slice.primary.secondaryLinkText}
                              />
                            </Button>
                          ) : item.cardColor === 'Framboise' ? (
                            <Button
                              field={slice.primary.secondaryLink}
                              theme="outline-white-raspberry"
                            >
                              <PrismicRichText
                                field={slice.primary.secondaryLinkText}
                              />
                            </Button>
                          ) : item.cardColor === 'Vert' ? (
                            <Button
                              field={slice.primary.secondaryLink}
                              theme="outline-white-green"
                            >
                              <PrismicRichText
                                field={slice.primary.secondaryLinkText}
                              />
                            </Button>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  );
                })}

                {prismicH.asText(slice.primary.googleReviews) ? (
                  <div className="hero-title__google-reviews">
                    <img
                      src="/icons/google-reviews.svg"
                      alt="Avis Google"
                      width="316"
                      height="74"
                    />

                    <PrismicRichText field={slice.primary.googleReviews} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {(slice.items || []).length > 0 ? (
            <div className="hero__right">
              <div className="hero__right__slider">
                <div className="hero__right__slider__scroll">
                  <div
                    className="hero__right__slider__wrap"
                    onScroll={handleScroll}
                    ref={scrollRef}
                  >
                    {slice.items.map((item, index) => {
                      return (
                        <div
                          className={classnames('hero__right__slider__slide', {
                            'hero__right__slider__slide--current':
                              index === currentBackgroundIndex,
                          })}
                          key={index}
                          {...{
                            [SLIDE_INDEX_ATTRIBUTE_NAME]: index,
                          }}
                        >
                          <div className="hero__right__slider__slide__gradient" />
                          <img
                            {...generateImageProps(
                              isMobile ? item.thumbnailMobile : item.thumbnail,
                              {
                                width: 600,
                                sizes: '228px',
                                lazy: index > 1,
                              }
                            )}
                          />

                          {item.logo ? (
                            <div className="hero__right__slider__slide__logo">
                              <img
                                {...generateImageProps(item.logo, {
                                  width: 600,
                                  sizes: '228px',
                                  lazy: index > 1,
                                })}
                                draggable="false"
                              />
                            </div>
                          ) : null}

                          {item.tags ? (
                            <div
                              className={classnames(
                                'hero__right__slider__tags',
                                {
                                  'hero__right__slider__tags--violet':
                                    item.cardColor === 'Violet',
                                  'hero__right__slider__tags--green':
                                    item.cardColor === 'Vert',
                                  'hero__right__slider__tags--raspberry':
                                    item.cardColor === 'Framboise',
                                }
                              )}
                            >
                              <PrismicRichText field={item.tags} />
                            </div>
                          ) : null}
                          <Link
                            className="hero__right__slider__slide__link"
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={handleMouseOut}
                            field={item.missionLink}
                          >
                            {item.title ? (
                              <PrismicRichText field={item.title} />
                            ) : null}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Hero;
