import React from 'react';
import classnames from 'classnames';

export const Title = ({
  borderTop = false,
  subTitle = null,
  titleUpperCase = false,
  subTitleUpperCase = false,
  children,
  color,
  align = 'left',
  size = 'medium',
}) => {
  return (
    <div
      className={classnames(
        'title',
        {
          'title--border-top': borderTop,
          'title--title-upper-case': titleUpperCase,
          'title--sub-title-upper-case': subTitleUpperCase,
        },

        `title--color-${color}`,
        `title--align-${align}`
      )}
    >
      <div className={classnames('title-title', `title--size-${size}`)}>
        {children}
      </div>

      {subTitle ? (
        <div
          className={classnames('title__sub-title', `subTitle--size-${size}`)}
        >
          {subTitle}
        </div>
      ) : null}
    </div>
  );
};
