import React from "react";
import { PrismicRichText } from "@prismicio/react";

import * as prismicH from "@prismicio/helpers";

import { BatMask } from "../../components/Icons";

import { Link } from "../../components/Link";

const NotFound = ({ slice }) => {
  return (
    <div className="not-found">
      <div className="not-found__content">
        <div className="not-found__title">
          <PrismicRichText field={slice.primary.title} />
        </div>
        <div className="not-found__icon">
          <BatMask />
        </div>
        <div className="not-found__text">
          <PrismicRichText field={slice.primary.text} />
        </div>
        <div className="not-found__link">
          <Link field={slice.primary.link}>
            <span>{prismicH.asText(slice.primary.linkText)}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
