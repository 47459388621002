import React, { useState } from 'react';

import { MaxWidth } from '../../components/MaxWidth';

import config from '../../helpers/config.js';
import { generateImageProps } from '../../helpers/image.js';

let increment = 1;

const originalUrl = (url) =>
  url.replace(/\?auto=compress,format/, '?auto=compress');

const getDownloadFileName = (url) => {
  const extension = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1];
  return `${config.siteName} ${increment++}.${extension}`;
};

const ImageItem = ({ item, variant }) => {
  const url = originalUrl(item.image.url);
  const handleClick = async (event) => {
    event.preventDefault();
    const Downloader = await import('js-file-downloader');
    new Downloader.default({
      url,
      filename: getDownloadFileName(url),
    });
  };

  return (
    <div className={`gallery__item ${variant}`}>
      <img
        {...generateImageProps(item.image, { width: 800, sizes: '400px' })}
      />
      <a href={url} target="_blank" onClick={handleClick}>
        <img
          src="/icons/download.svg"
          alt="Télécharger"
          width="34"
          height="34"
          draggable={false}
        />
        Télécharger la photo
      </a>
    </div>
  );
};

const DesktopGallery = ({ items }) =>
  items.map((item, i) => <ImageItem key={i} item={item} variant="desktop" />);

const MobileGallery = ({ items }) => {
  const [itemsToShow, setItemsToShow] = useState(1);

  const groupedItems = [];
  for (let i = 0; i < items.length; i += 3) {
    groupedItems.push(items.slice(i, i + 3));
  }

  return (
    <div className="gallery__container-mobile__grid">
      {groupedItems.slice(0, itemsToShow).map((group, idx) => (
        <React.Fragment key={idx}>
          {group[0] && <ImageItem item={group[0]} variant="mobile-fullwidth" />}

          {(group[1] || group[2]) && (
            <div className="gallery__container-mobile__item-halfwidth">
              {group[1] && (
                <ImageItem item={group[1]} variant="mobile-halfwidth" />
              )}
              {group[2] && (
                <ImageItem item={group[2]} variant="mobile-halfwidth" />
              )}
            </div>
          )}
        </React.Fragment>
      ))}
      {itemsToShow < groupedItems.length && (
        <button
          className="gallery__show-more-button"
          onClick={() => setItemsToShow(groupedItems.length)}
        >
          Voir plus
        </button>
      )}
    </div>
  );
};

const PresseGallery = ({ slice: { items } }) => (
  <div className="gallery">
    <MaxWidth>
      <div className="gallery__container-desktop">
        <DesktopGallery items={items} />
      </div>
      <div className="gallery__container-mobile">
        <MobileGallery items={items} />
      </div>
    </MaxWidth>
  </div>
);

export default PresseGallery;
