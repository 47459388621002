import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import classnames from 'classnames';
import { Link } from '../../components/Link';

import { generateImageProps } from '../../helpers/image.js';

const Experiences = ({ slice }) => {
  return (
    <div className="experiences">
      <MaxWidth>
        <div className="experiences__container">
          {slice.items.map((item, index) => {
            return (
              <div
                className={classnames('experiences__item', {
                  'experiences__item--violet': item.color === 'violet',
                  'experiences__item--green': item.color === 'vert',
                  'experiences__item--raspberry': item.color === 'framboise',
                })}
                key={index}
              >
                <div className="experiences__item__tags">
                  {item.tags ? <PrismicRichText field={item.tags} /> : null}
                </div>

                <div className="experiences__item__wrap">
                  <div className="experiences__item__logo">
                    <Link field={item.buttonLink}>
                      {item.logo ? (
                        <img
                          {...generateImageProps(item.logo, {
                            width: 400,
                            sizes: '100vw',
                          })}
                        />
                      ) : null}
                    </Link>
                  </div>
                  <div className="experiences__item__description">
                    {item.description ? (
                      <PrismicRichText field={item.description} />
                    ) : null}
                  </div>
                  {item.buttonLinkText ? (
                    <Button
                      field={item.buttonLink}
                      theme={
                        item.color === 'violet'
                          ? 'violet'
                          : item.color === 'vert'
                          ? 'green'
                          : 'raspberry'
                      }
                      rightIcon="right-arrow"
                    >
                      {item.buttonLinkText ? (
                        <PrismicRichText field={item.buttonLinkText} />
                      ) : null}
                    </Button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Experiences;
