import React from "react";
import classnames from "classnames";
import { StarsIcon } from "../Icons";

export const Stars = ({ difficulty, color = "white" }) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= difficulty) {
      stars.push(
        <div className="stars--filled" key={i}>
          <StarsIcon />
        </div>
      );
    } else {
      stars.push(
        <div className="stars--empty" key={i}>
          <StarsIcon />
        </div>
      );
    }
  }

  return (
    <div className={classnames("stars", `stars--color-${color}`)}>{stars}</div>
  );
};
