import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';
import { Link } from '../../components/Link';
import { isLink } from '../../helpers/is-link.js';
import { generateImageProps } from '../../helpers/image';

const PresseItems = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <div
          key={index}
          className={classnames(
            'presse-eco__item',
            {
              'presse-eco__item-highlight': prismicH.asImageSrc(item.image),
            },
            {
              'presse-eco__item-card': !prismicH.asImageSrc(item.image),
            }
          )}
        >
          {prismicH.asImageSrc(item.image) ? (
            <div className="presse-eco__item-image">
              <img
                {...generateImageProps(item.image, {
                  sizes: '(min-width: 769px) 400px, 85vw',
                })}
              />
            </div>
          ) : null}

          {prismicH.asImageSrc(item.image) && item.title && item.subtitle ? (
            <div className="presse-eco__item-title-subtitle">
              <Title size="small">
                <PrismicRichText field={item.title} />
                -
                <PrismicRichText field={item.subtitle} />
              </Title>
            </div>
          ) : (
            <>
              {item.subtitle ? (
                <div className="presse-eco__item-subtitle">
                  <PrismicRichText field={item.subtitle} />
                </div>
              ) : null}

              {item.title ? (
                <div className="presse-eco__item-title">
                  <Title size="small">
                    <PrismicRichText field={item.title} />
                  </Title>
                </div>
              ) : null}
            </>
          )}

          {item.content && item.content.length > 0 ? (
            <div className="presse-eco__item-content">
              <PrismicRichText field={item.content} />
            </div>
          ) : null}

          {isLink(item.link) ? (
            <div className="presse-eco__item-link">
              <Link field={item.link}>
                {prismicH.asImageSrc(item.image)
                  ? prismicH.asText(item.linkText)
                  : null}
              </Link>

              <img src="/icons/arrow-right-blue.svg" alt="Flèche droite" />
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
};

const PresseEcho = ({ slice }) => {
  const itemsWithImage = slice.items.filter((item) =>
    prismicH.asImageSrc(item.image)
  );
  const itemsWithoutImage = slice.items.filter(
    (item) => !prismicH.asImageSrc(item.image)
  );

  return (
    <div className="presse-eco">
      <MaxWidth>
        {slice.primary.title ? (
          <div className="presse-eco__title">
            <Title
              size="medium"
              color={slice.primary.color}
              borderTop
              titleUpperCase
            >
              <PrismicRichText field={slice.primary.title} />
            </Title>
          </div>
        ) : null}

        {itemsWithImage ? (
          <div className="presse-eco__content">
            <PresseItems items={itemsWithImage} />
          </div>
        ) : null}

        {itemsWithoutImage ? (
          <div className="presse-eco__content-row">
            <PresseItems items={itemsWithoutImage} />
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};

export default PresseEcho;
