import { PrismicRichText } from '@prismicio/react';
import { useRouter } from 'next/router';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import { useLazyMapboxMap } from '../../components/MapboxMap/lazy';

import { generateImageProps } from '../../helpers/image';
import { useGlobalContext } from '../../helpers/context';

const ICONS = {
  metro: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/metro.svg"
      width="19"
      height="19"
      alt=""
    />
  ),
  bus: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/bus.svg"
      width="19"
      height="19"
      alt=""
    />
  ),
  tramway: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/tramway.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  voiture: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/voiture.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  parking: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/parking.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  velo: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/velo.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  user: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/user.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  userStar: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/user-star.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  users: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/users.svg"
      width="19"
      height="19"
      alt=""
    />
  ),

  clock: (
    <img
      loading="lazy"
      src="/icons/compact-convenient/clock.svg"
      width="16"
      height="16"
      alt=""
    />
  ),
};

const COLUMN_TYPE = {
  TARIFF: 'tariff',
  SCHEDULE: 'schedule',
  TRANSPORT: 'transport',
  ROAD: 'road',
};

const Map = ({ fallback, timeout }) => {
  const LazyMapboxMap = useLazyMapboxMap({
    fallback,
    timeout,
  });

  return <LazyMapboxMap />;
};

const CompactConvenient = ({ slice }) => {
  const { nonDynamicCompatibleHref } = useGlobalContext();
  const router = useRouter();

  const items = useMemo(() => {
    return Object.values(COLUMN_TYPE).map((type) => ({
      type,
      values: slice.items.filter((item) => item.column === type),
      title:
        slice.primary[`title${type.charAt(0).toUpperCase() + type.slice(1)}`],
    }));
  }, [slice.items]);

  const [mapbox, setMapbox] = useState(false);

  const fallback = slice.primary.image ? (
    <img
      loading="lazy"
      {...generateImageProps(slice.primary.image, {
        sizes: '(min-width: 768px) 85vw, 540px',
        lazy: true,
      })}
      draggable="false"
    />
  ) : null;

  return (
    <div id={slice.primary.anchor || null} className="compact-convenient">
      <MaxWidth>
        <div className="compact-convenient__container">
          <div className="compact-convenient__content">
            <div className="compact-convenient__title">
              {slice.primary.title ? (
                <PrismicRichText field={slice.primary.title} />
              ) : null}
            </div>
            <div className="compact-convenient__list__container">
              {items.map((column, index) => {
                return (
                  <div key={index} className="compact-convenient__list__item">
                    {prismicH.asText(column.title) ? (
                      <div className="compact-convenient__shipping__title">
                        <h3>{prismicH.asText(column.title)}</h3>
                      </div>
                    ) : null}
                    {column.values.map((item, index) => {
                      let split = null;
                      if (item.content.length > 0) {
                        split = item.content[0].text.split('|');
                      }
                      return (
                        <div
                          className={classNames(
                            'compact-convenient__shipping',
                            {
                              'compact-convenient__shipping--no-icon':
                                !ICONS[item.icon],
                            }
                          )}
                          key={index}
                        >
                          {ICONS[item.icon] && (
                            <div className="compact-convenient__shipping__item__icon">
                              {ICONS[item.icon]}
                            </div>
                          )}
                          <div className="compact-convenient__shipping__item__text">
                            {item.content ? (
                              split.length > 1 ? (
                                <div className="compact-convenient__shipping__item__text__split">
                                  <p>{split[0]}</p>

                                  <p>{split[1]}</p>
                                </div>
                              ) : (
                                <PrismicRichText field={item.content} />
                              )
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="compact-convenient__map">
            <div className="compact-convenient__map__wrap">
              {mapbox ? (
                <Map
                  fallback={fallback}
                  timeout={
                    nonDynamicCompatibleHref.includes(router?.asPath)
                      ? undefined
                      : 0
                  }
                />
              ) : (
                <button
                  onTouchStart={() => setMapbox(true)}
                  onMouseDown={() => setMapbox(true)}
                  onClick={() => setMapbox(true)}
                >
                  {fallback}
                </button>
              )}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default CompactConvenient;
