import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import classNames from 'classnames';

const ArticleSingle = ({ slice }) => {
  return (
    <div
      className={classNames('article-content-single', {
        'article-content-single--little-images': slice.primary.littleImages,
      })}
    >
      <MaxWidth>
        <PrismicRichText field={slice.primary.content} />
      </MaxWidth>
    </div>
  );
};

export default ArticleSingle;
