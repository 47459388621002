import React, { useEffect } from 'react';
import * as prismicH from '@prismicio/helpers';

import { MaxWidth } from '../../components/MaxWidth';

const SCRIPT_ID = 'forescape-script';

const Booking = ({ slice }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!document.querySelector(`#${SCRIPT_ID}`)) {
        const script = document.createElement('script');
        script.src = 'https://widgets.4escape.app/main.js';
        script.defer = true;
        script.type = 'module';
        script.id = SCRIPT_ID;
        document.head.appendChild(script);

        const link = document.createElement('link');
        link.href = 'https://widgets.4escape.app/main.css';
        link.rel = 'stylesheet';
        link.crossOrigin = 'anonymous';
        document.head.appendChild(link);
      }
    }
  }, []);

  const customCode = prismicH.asText(slice.primary.customCode);

  return (
    <div className="booking">
      <MaxWidth>
        {prismicH.asText(slice.primary.title) ? (
          <h2 className="booking__title">
            {prismicH.asText(slice.primary.title)}
          </h2>
        ) : null}

        <div className="booking__embed">
          <h2>{slice.primary.forescapeWidgetId}</h2>

          {customCode ? (
            <div dangerouslySetInnerHTML={{ __html: customCode }} />
          ) : (
            <div
              className="forescape-catalog"
              data-widget-id={
                slice.primary.forescapeWidgetId ||
                '54ab4331-f718-4d09-955e-201bea72552b'
              }
              data-settings="b64.eyJkb21haW4iOiJiYXRtYW5lc2NhcGUuNGVzY2FwZS5pbyJ9"
            />
          )}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Booking;
