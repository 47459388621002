import React from 'react';

function Spacer({ slice }) {
  var { spaceValue, phoneSpaceValue, theme, phoneTheme } = slice.primary;

  if (theme === 'dark-blue') {
    theme = 'var(--primary-600)';
  }

  const spacerStyle = {
    '--height': `${spaceValue}px`,
    '--height-mobile': `${phoneSpaceValue || spaceValue}px`,

    '--background-color': theme,
    '--background-color-mobile': phoneTheme || theme,
  };

  return <div className="spacer-slice" style={spacerStyle}></div>;
}

export default Spacer;
