import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';
import React from 'react';

import { generateImageProps, isImage } from '../../helpers/image';

import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import { Title } from '../../components/Title';
import { isLink } from '../../helpers/is-link';

const ImageText = ({ slice, slices }) => {
  const isFirst = slices.length > 0 && slices[0].id === slice.id;
  const hasBackground = isImage(slice.primary.background);
  const hasImageGradient = slice.primary.imageGradient;
  const hasImageCover = slice.primary.imageCover;
  const isReverse = slice.primary.reverse;
  const backgroundTheme = slice.primary.backgroundTheme || 'Dark';

  return (
    <div
      id={slice.primary.anchor || null}
      className={classNames('image-text', {
        'image-text--first': isFirst,
        'image-text--reverse': isReverse,
        'image-text--no-image-gradient': !hasImageGradient,
        'image-text--no-image-cover': !hasImageCover,
        'image-text--has-background': hasBackground,
        'image-text--bg-white': backgroundTheme === 'White',
      })}
    >
      <MaxWidth>
        <div className="image-text__gradient" />
        {hasBackground && (
          <img
            className="image-text__background"
            {...generateImageProps(slice.primary.background, {
              width: 1200,
              sizes: '600px',
            })}
            draggable={false}
          />
        )}
        <div className="image-text__container">
          <div className="image-text__content">
            <Title
              borderTop
              titleUpperCase
              subTitleUpperCase
              align="left"
              color={slice.primary.color}
              subTitle={
                slice.primary.subTitle ? (
                  <PrismicRichText field={slice.primary.subTitle} />
                ) : null
              }
            >
              {slice.primary.title ? (
                isFirst ? (
                  <h1>{prismicH.asText(slice.primary.title)}</h1>
                ) : (
                  <h2>{prismicH.asText(slice.primary.title)}</h2>
                )
              ) : null}
            </Title>

            {slice.primary.description ? (
              <div className="image-text__paragraph">
                <PrismicRichText field={slice.primary.description} />
              </div>
            ) : null}

            <div className="image-text__buttons">
              <div>
                {prismicH.asText(slice.primary.buttonLinkText) &&
                isLink(slice.primary.buttonLink) ? (
                  <Button
                    field={slice.primary.buttonLink}
                    theme={
                      slice.primary.color === 'yellow'
                        ? 'border-black'
                        : ['violet', 'green', 'raspberry', 'yellow'].includes(
                            slice.primary.color
                          )
                        ? slice.primary.color
                        : 'primary'
                    }
                  >
                    <PrismicRichText field={slice.primary.buttonLinkText} />
                  </Button>
                ) : null}

                {prismicH.asText(slice.primary.secondButtonLinkText) &&
                isLink(slice.primary.secondButtonLink) ? (
                  <Button
                    field={slice.primary.secondButtonLink}
                    theme={
                      ['violet', 'green', 'raspberry', 'yellow'].includes(
                        slice.primary.color
                      )
                        ? slice.primary.color
                        : 'primary'
                    }
                  >
                    <PrismicRichText
                      field={slice.primary.secondButtonLinkText}
                    />
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          {isImage(slice.primary.image) ? (
            <div className="image-text__image">
              <div className="image-text__image__gradient" />

              <div className="image-text__image__overlay" />
              <div className="image-text__image__wrap">
                <img
                  {...generateImageProps(slice.primary.image, {
                    width: 1200,
                    sizes: '600px',
                    lazy: true,
                  })}
                />
              </div>
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

export default ImageText;
