import { PrismicRichText } from '@prismicio/react';
import * as EmailValidator from 'email-validator';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import slug from 'slug';
import * as prismicH from '@prismicio/helpers';

import { Button } from '../../components/Button';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const FORM_TYPE = {
  TEAM_BUILDING: 'tb',
  BIRTHDAY: 'anniversaire',
  EVG: 'evg',
  EVJF: 'evjf',
};

export const QuotationForm = ({ slice }) => {
  const formRef = useRef(null);

  const [state, setState] = useState(STATE.IDLE);

  const [mail, setMail] = useState('');
  const [company, setCompany] = useState('');
  const [firstName, setFirstName] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [number, setNumber] = useState('');
  const [age, setAge] = useState('');
  const [playerNumber, setPlayerNumber] = useState('');
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [offer, setOffer] = useState('');
  const [mission, setMission] = useState('');
  const [option, setOption] = useState([]);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  // Verify empty fields

  useEffect(() => {
    const data = {
      firstName,
      name,
      mail,
      company,
      message,
      number,
      age,
      playerNumber,
      date,
      hour,
      offer,
      mission,
      option,
    };

    const emptyFields = Object.keys(data).filter((key) => {
      const label = Object.keys(slice.primary).find(
        (item) => item.toLowerCase().replace('label', '') === key.toLowerCase()
      );

      const value =
        typeof slice.primary[label] === 'string'
          ? slice.primary[label]
          : prismicH.asText(slice.primary[label]);

      return label && value && value.includes('*') && data[key].length <= 0;
    });

    setSubmitDisabled(
      emptyFields.length > 0 ||
        [STATE.LOADING, STATE.SUCCESS].includes(state) ||
        EmailValidator.validate(mail) === false
    );
  }, [
    mail,
    company,
    firstName,
    name,
    message,
    number,
    age,
    playerNumber,
    date,
    hour,
    offer,
    mission,
    option,
  ]);

  const handleCheckboxToggle = (value, active) => {
    setOption((current) => {
      const result = (current || []).slice();

      if (result.indexOf(value) === -1) {
        result.push(value);

        return result;
      }

      return result.filter((item) => {
        return item !== value;
      });
    });
  };

  const handleSubmit = (event) => {
    setState(STATE.LOADING);

    event.preventDefault();

    const data = {
      site: 'Batman Escape',
      formType: slice.primary.formType || FORM_TYPE.BIRTHDAY,
      firstName,
      name,
      mail,
      company,
      message,
      number,
      age,
      playerNumber,
      date,
      hour,
      offer,
      mission,
      option,
    };

    Sentry.withScope((scope) => {
      scope.setExtras(data);

      Sentry.captureMessage('Quotation form submit');
    });

    fetch(`/api/quote`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          setState(STATE.ERROR);

          Sentry.withScope((scope) => {
            scope.setExtras(data);

            Sentry.captureException(new Error('Quotation form submit error'));
          });
        } else {
          setState(STATE.SUCCESS);
        }
      })
      .catch((error) => {
        setState(STATE.ERROR);

        Sentry.withScope((scope) => {
          scope.setExtras(data);

          Sentry.captureException(new Error(error));
        });
      });
  };

  return (
    <div
      id={slice.primary.anchor || null}
      className={classnames('quotation__form', {
        'quotation__form--state-loading': state === STATE.LOADING,
        'quotation__form--state-success': state === STATE.SUCCESS,
      })}
    >
      {slice.primary.formType === FORM_TYPE.TEAM_BUILDING ? (
        <form
          className="quotation__from__wrap"
          onSubmit={handleSubmit}
          id="quotation-form"
          ref={formRef}
        >
          <div className="quotation__form__left">
            <div className="quotation__form__split">
              {slice.primary.labelFirstName && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    placeholder={slice.primary.labelFirstName}
                    value={firstName}
                    onInput={(event) => setFirstName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}

              {slice.primary.labelName && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="text"
                    name="name"
                    autoComplete="family-name"
                    placeholder={slice.primary.labelName}
                    value={name}
                    onInput={(event) => setName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}
            </div>

            <div className="quotation__form__split">
              {slice.primary.labelMail && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder={slice.primary.labelMail}
                    value={mail}
                    onInput={(event) => setMail(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}

              {slice.primary.labelNumber && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="tel"
                    name="number"
                    autoComplete="tel"
                    placeholder={slice.primary.labelNumber}
                    value={number}
                    onInput={(event) => setNumber(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}
            </div>

            <div className="quotation__form__split">
              {slice.primary.labelDate && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="date"
                    name="date"
                    autoComplete="no"
                    placeholder={slice.primary.labelDate}
                    value={date}
                    onInput={(event) => setDate(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}

              {slice.primary.labelPlayerNumber && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input quotation__form__input--high"
                    type="number"
                    name="playerNumber"
                    autoComplete="no"
                    placeholder={slice.primary.labelPlayerNumber}
                    value={playerNumber}
                    onInput={(event) => setPlayerNumber(event.target.value)}
                    readOnly={state === STATE.LOADING}
                    min="0"
                  />
                </div>
              )}
            </div>

            {slice.primary.labelMessage && (
              <div className="quotation__form__input-container-textarea">
                <textarea
                  className="quotation__form__textarea"
                  name="message"
                  autoComplete="no"
                  placeholder={slice.primary.labelMessage}
                  value={message}
                  onInput={(event) => setMessage(event.target.value)}
                  readOnly={state === STATE.LOADING}
                />
              </div>
            )}
          </div>

          <div className="quotation__form__cta">
            <Button
              horizontalPadding="large"
              theme="right-arrow-rounded"
              disabled={submitDisabled}
            >
              {slice.primary.labelSubmit}
            </Button>

            {state === STATE.ERROR ? (
              <div className="quotation__form__cta__error">
                <img
                  src="/icons/exclamation-mark-circle-white.svg"
                  alt=""
                  width="323"
                  height="323"
                  draggable="false"
                />

                <PrismicRichText field={slice.primary.errorMessage} />
              </div>
            ) : null}
          </div>
        </form>
      ) : (
        <form
          className="quotation__from__wrap quotation__from__wrap--grid"
          onSubmit={handleSubmit}
          id="quotation-form"
          ref={formRef}
        >
          <div className="quotation__form__left">
            <div className="quotation__form__split">
              {slice.primary.labelFirstName && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    placeholder={slice.primary.labelFirstName}
                    value={firstName}
                    onInput={(event) => setFirstName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}

              {slice.primary.labelName && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="text"
                    name="name"
                    autoComplete="family-name"
                    placeholder={slice.primary.labelName}
                    value={name}
                    onInput={(event) => setName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}
            </div>

            {slice.primary.labelMail && (
              <div className="quotation__form__input-container">
                <input
                  className="quotation__form__input"
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder={slice.primary.labelMail}
                  value={mail}
                  onInput={(event) => setMail(event.target.value)}
                  readOnly={state === STATE.LOADING}
                />
              </div>
            )}

            {slice.primary.labelNumber && (
              <div className="quotation__form__input-container">
                <input
                  className="quotation__form__input"
                  type="tel"
                  name="number"
                  autoComplete="tel"
                  placeholder={slice.primary.labelNumber}
                  value={number}
                  onInput={(event) => setNumber(event.target.value)}
                  readOnly={state === STATE.LOADING}
                />
              </div>
            )}

            {slice.primary.formType &&
            slice.primary.formType === FORM_TYPE.BIRTHDAY ? (
              <div className="quotation__form__split">
                {slice.primary.labelPlayerNumber && (
                  <div className="quotation__form__input-container">
                    <input
                      className="quotation__form__input"
                      type="number"
                      name="playerNumber"
                      autoComplete="no"
                      placeholder={slice.primary.labelPlayerNumber}
                      value={playerNumber}
                      onInput={(event) => setPlayerNumber(event.target.value)}
                      readOnly={state === STATE.LOADING}
                      min="0"
                    />
                  </div>
                )}

                {slice.primary.labelAge && (
                  <div className="quotation__form__input-container">
                    <input
                      className="quotation__form__input"
                      type="number"
                      name="age"
                      autoComplete="no"
                      placeholder={slice.primary.labelAge}
                      value={age}
                      onInput={(event) => setAge(event.target.value)}
                      readOnly={state === STATE.LOADING}
                      min="0"
                    />
                  </div>
                )}
              </div>
            ) : (
              slice.primary.labelPlayerNumber && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="number"
                    name="playerNumber"
                    autoComplete="no"
                    placeholder={slice.primary.labelPlayerNumber}
                    value={playerNumber}
                    onInput={(event) => setPlayerNumber(event.target.value)}
                    readOnly={state === STATE.LOADING}
                    min="0"
                  />
                </div>
              )
            )}

            <div className="quotation__form__split">
              {slice.primary.labelDate && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="date"
                    name="date"
                    autoComplete="no"
                    placeholder={slice.primary.labelDate}
                    value={date}
                    onInput={(event) => setDate(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}

              {slice.primary.labelHour && (
                <div className="quotation__form__input-container">
                  <input
                    className="quotation__form__input"
                    type="time"
                    name="time"
                    autoComplete="no"
                    placeholder={slice.primary.labelHour}
                    value={hour}
                    onInput={(event) => setHour(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              )}
            </div>

            {slice.primary.labelMessage && (
              <div className="quotation__form__input-container-textarea">
                <textarea
                  className="quotation__form__textarea"
                  name="message"
                  autoComplete="no"
                  placeholder={slice.primary.labelMessage}
                  value={message}
                  onInput={(event) => setMessage(event.target.value)}
                  readOnly={state === STATE.LOADING}
                />
              </div>
            )}
          </div>

          <div className="quotation__form__right">
            {slice.primary.labelOffer ? (
              <div className="quotation__content__container__offer">
                <p>{prismicH.asText(slice.primary.labelOffer)}</p>

                <div className="select">
                  <select
                    className={classnames('quotation__form__select', {
                      'quotation__form__select-selected': offer !== '',
                    })}
                    value={offer}
                    onChange={(event) => {
                      setOffer(event.target.value);
                    }}
                  >
                    <option value="" disabled>
                      {slice.primary.emptyOffer}
                    </option>

                    {slice.primary.listOffer.map((item) => {
                      return (
                        <option key={item?.text} value={item?.text}>
                          {item?.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}

            {slice.primary.textOffer ? (
              <div className="quotation__content__offer__text">
                <PrismicRichText field={slice.primary.textOffer} />
              </div>
            ) : null}

            <div className="quotation__content__container__offer">
              <p>{prismicH.asText(slice.primary.labelMission)}</p>

              <div className="select">
                <select
                  className={classnames('quotation__form__select', {
                    'quotation__form__select-selected': mission !== '',
                  })}
                  value={mission}
                  onChange={(event) => {
                    setMission(event.target.value);
                  }}
                >
                  <option value="" disabled>
                    {slice.primary.emptyMission}
                  </option>

                  {slice.primary.listMission.map((item) => {
                    return (
                      <option key={item?.text} value={item?.text}>
                        {item?.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {slice.primary.labelOption ? (
              <div className="quotation__content__container__offer">
                <p>{prismicH.asText(slice.primary.labelOption)}</p>

                {slice.primary.listOption.map((item) => {
                  const name = slug(item?.text);
                  const value = item?.text;

                  return (
                    <label
                      className="quotation__form__checkbox"
                      key={item?.text}
                      value={item?.text}
                      htmlFor={name}
                    >
                      <input
                        type="checkbox"
                        name={name}
                        id={name}
                        checked={option.includes(value)}
                        onChange={() => {
                          handleCheckboxToggle(value);
                        }}
                      />

                      <span>{item?.text}</span>
                    </label>
                  );
                })}
              </div>
            ) : null}

            <div className="quotation__form__cta">
              <Button
                horizontalPadding="large"
                theme="right-arrow-rounded"
                disabled={submitDisabled}
              >
                {slice.primary.labelSubmit}
              </Button>

              {state === STATE.ERROR ? (
                <div className="quotation__form__cta__error">
                  <img
                    src="/icons/exclamation-mark-circle-white.svg"
                    alt=""
                    width="323"
                    height="323"
                    draggable="false"
                  />

                  <PrismicRichText field={slice.primary.errorMessage} />
                </div>
              ) : null}
            </div>
          </div>
        </form>
      )}

      {state === STATE.SUCCESS ? (
        <div className="quotation__form__success">
          <img
            src="/icons/checkmark-circle-blue.svg"
            alt=""
            width="271"
            height="270"
            draggable="false"
          />

          <PrismicRichText field={slice.primary.successMessage} />
        </div>
      ) : null}
    </div>
  );
};
