import { useMemo } from 'react';
import dynamic from 'next/dynamic';

const MAPBOX_MAP_TIMEOUT = 2000;

export const useLazyMapboxMap = ({
  fallback,
  timeout = MAPBOX_MAP_TIMEOUT,
}) => {
  return useMemo(() => {
    return dynamic(
      () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            return resolve(import('./index.js'));
          }, timeout);
        });
      },
      {
        loading: () => {
          return fallback;
        },
        ssr: false,
      }
    );
  }, []);
};
