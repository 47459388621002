import { PrismicRichText } from '@prismicio/react';
import React from 'react';
import { MaxWidth } from '../../components/MaxWidth';

const ArticleContentTop = ({ slice }) => {
  return (
    <div className="article-content-top">
      <MaxWidth>
        <div className="article-content-top__left">
          <PrismicRichText field={slice.primary.leftContent} />
        </div>
        <div className="article-content-top__right">
          <PrismicRichText field={slice.primary.rightContent} />
        </div>
      </MaxWidth>
    </div>
  );
};

export default ArticleContentTop;
