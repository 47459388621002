import React from 'react';
import { MaxWidth } from '../../components/MaxWidth';
import { generateImageProps } from '../../helpers/image';

const ArticleImageBanner = ({ slice }) => (
  <div className="article-image-banner">
    <MaxWidth>
      <img
        className="article-image-banner__background"
        {...generateImageProps(slice.primary.image, {
          width: 1200,
          sizes: '600px',
        })}
        draggable={false}
      />
    </MaxWidth>
  </div>
);

export default ArticleImageBanner;
