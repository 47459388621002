import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { MaxWidth } from '../../components/MaxWidth';
import * as prismicH from '@prismicio/helpers';
import { useEffect } from 'react';
import { Button } from '../../components/Button';

const INNER_SCROLL = 250;
const SELECTOR_STATE = {
  TOP: 'top',
  BOTTOM: 'bottom',
  MIDDLE: 'middle',
};

const LegalMention = ({ slice, slices }) => {
  const [currentIndexSection, setCurrentIndexSection] = useState(-1);
  const [selectorState, setSelectorState] = useState(SELECTOR_STATE.TOP);
  const [selectorInnerScroll, setSelectorInnerScroll] = useState(
    SELECTOR_STATE.TOP
  );

  const sectionsRef = useRef([]);
  const sectionRef = useRef(null);
  const selectorRef = useRef(null);

  const isFirst = slices.length > 0 && slices[0].id === slice.id;
  const hasSelector = slice.items.length > 1;

  // ADD SCROLL EVENT
  useEffect(() => {
    if (hasSelector) {
      window.addEventListener('scroll', getScrollSection);
      return () => {
        window.removeEventListener('scroll', getScrollSection);
      };
    }
  });

  // GET IF SECTION IS ON SCROLL
  const isOnSection = (section, innerScrollTop = 0, innerScrollBottom = 0) => {
    if (!section) return;
    const scrollPosition = window.scrollY;
    const sectionTop = section.getBoundingClientRect().top + scrollPosition;
    const sectionBottom =
      section.getBoundingClientRect().bottom + scrollPosition;

    return {
      top: scrollPosition + innerScrollTop < sectionTop,
      middle:
        scrollPosition + innerScrollTop >= sectionTop &&
        scrollPosition + innerScrollBottom < sectionBottom,
      bottom: scrollPosition + innerScrollBottom >= sectionBottom,
    };
  };

  useEffect(() => {
    if (hasSelector) {
      setSelectorInnerScroll(
        selectorRef.current.getBoundingClientRect().bottom -
          selectorRef.current.getBoundingClientRect().top
      );
    }
  }, []);

  // GET SCROLL SECTION
  const getScrollSection = () => {
    const isOnGlobalSection = isOnSection(
      sectionRef.current,
      100,
      selectorInnerScroll * 1.28
    );
    if (!isOnGlobalSection) return;
    if (isOnGlobalSection.top) setSelectorState(SELECTOR_STATE.TOP);
    if (isOnGlobalSection.bottom) setSelectorState(SELECTOR_STATE.BOTTOM);
    sectionsRef.current.forEach((section, index) => {
      if (isOnSection(section, INNER_SCROLL, INNER_SCROLL).middle) {
        if (isOnGlobalSection.middle) setSelectorState(SELECTOR_STATE.MIDDLE);
        setCurrentIndexSection(index);
      }
    });
  };

  // GO TO SECTION
  const goToSection = (index) => {
    const scroll = Math.round(
      sectionsRef.current[index].getBoundingClientRect().top +
        document.documentElement.scrollTop -
        INNER_SCROLL / 2
    );
    window.scrollTo({ top: scroll, behavior: 'smooth' });
  };

  return (
    <div
      className={classNames('legal-mention', {
        'legal-mention--is-first': isFirst,
        'legal-mention--selector': hasSelector,
      })}
    >
      <MaxWidth>
        {hasSelector && (
          <div className="legal-mention__left">
            <div
              className={classNames(
                `legal-mention__left__selector legal-mention__left__selector--${selectorState}`
              )}
              ref={selectorRef}
            >
              <p>{prismicH.asText(slice.primary.title)}</p>
              <div className="legal-mention__left__selector__content">
                {slice.items.map((item, index) => {
                  return (
                    prismicH.asText(item.title) && (
                      <button
                        key={index}
                        className={classNames(
                          'legal-mention__left__selector__content__button',
                          {
                            'legal-mention__left__selector__content__button--active':
                              currentIndexSection === index,
                          }
                        )}
                        onClick={() => goToSection(index)}
                      >
                        {prismicH.asText(item.title)}
                      </button>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="legal-mention__content" ref={sectionRef}>
          <div className="legal-mention__content__line" />
          <PrismicRichText field={slice.primary.title} />
          {prismicH.asText(slice.primary.subtitle) && (
            <h2>{prismicH.asText(slice.primary.subtitle)}</h2>
          )}
          {slice.items.map((item, index) => {
            return (
              <div
                key={index}
                ref={(el) => (sectionsRef.current[index] = el)}
                className="legal-mention__content__section"
              >
                {prismicH.asText(item.title) && (
                  <h3>{prismicH.asText(item.title)}</h3>
                )}
                <div className="legal-mention__content__section__content">
                  <PrismicRichText field={item.content} />
                </div>
              </div>
            );
          })}
        </div>
        {slice.primary.buttonCookies && slice.primary.buttonCookiesLabel ? (
          <div className="legal-mention__button">
            <Button
              theme="primary"
              field={slice.primary.buttonCookiesLink}
              target="_self"
            >
              {prismicH.asText(slice.primary.buttonCookiesLabel)}
            </Button>
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};

export default LegalMention;
