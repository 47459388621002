import { useEffect, useRef, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import screenfull from 'screenfull';
import classnames from 'classnames';
import * as prismicH from '@prismicio/helpers';

import { MaxWidth } from '../../components/MaxWidth';

import { generateImageProps } from '../../helpers/image';

const DynamicReactPlayer = dynamic(
  () => import('react-player').then((mod) => mod.default),
  {
    loading: () => <p></p>,
    ssr: false,
  }
);

const Video = ({ slice }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const [mountEmbed, setMountEmbed] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(!isMobile);
  const [isFullScreen, setIsFullScreen] = useState(screenfull.isFullscreen);
  const player = useRef(null);
  const videoPlayer = useRef(null);
  const playerContainer = useRef(null);

  const [isHover, setIsHover] = useState(false);

  if (screenfull.isEnabled) {
    screenfull.on('change', () => {
      setIsFullScreen(screenfull.isFullscreen);
    });
  }

  const handlePlayPause = (e = null, toggle = null) => {
    if (player.current) {
      setIsPlaying((current) => (!toggle ? !current : toggle));

      if (!e) return;

      switch (e.detail) {
        case 2: {
          handleFullScreen();
          break;
        }
      }
    }
  };

  const handleStart = () => {
    if (player.current) {
      if (isStarted) {
        handlePlayPause();
      } else {
        player.current.seekTo(0);

        setIsMuted(false);
        setIsPlaying(true);

        setIsStarted(true);
      }
    }
  };

  const handleFullScreen = (e, toggle = null) => {
    if (player.current) {
      screenfull.toggle(playerContainer.current);

      if (!isStarted) {
        handleStart();
      }
    }
  };

  const handleReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);

  const videoUrl = slice.primary.video.embed_url;

  const youtubeIdMatch = videoUrl && videoUrl.match(/[?&]v=([^&]+)/);

  const youtubeId = youtubeIdMatch ? youtubeIdMatch[1] : null;

  return (
    <div
      className={classnames('video', {
        'video--background-dark': slice.primary.backgroundTheme === 'Dark',
        'video--background-white': slice.primary.backgroundTheme === 'White',
        'video--16by9': slice.primary.ratio === '16:9',
        'video--4by3': slice.primary.ratio === '4:3',
        'video--1by1': slice.primary.ratio === '1:1',
        'video--9by16': slice.primary.ratio === '9:16',
      })}
    >
      <div className="video__transitions">
        <img
          loading="lazy"
          className="video__transition"
          {...generateImageProps({
            url:
              slice.primary.backgroundTheme === 'Dark'
                ? `/illustrations/transition-bats-black.svg`
                : `/illustrations/transition-bats-white.svg`,
            alt: 'Bats section background',
            draggable: false,
          })}
        />
      </div>
      <MaxWidth>
        <div
          className={classnames('video__content-container', {
            'video__content-container--dark':
              slice.primary.backgroundTheme === 'Dark',
            'video__content-container--white':
              slice.primary.backgroundTheme === 'White',
          })}
        >
          {prismicH.asText(slice.primary.title) ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
          {prismicH.asText(slice.primary.description) ? (
            <p className="video__text-description">
              {prismicH.asText(slice.primary.description)}
            </p>
          ) : null}
        </div>
        {slice.primary.video ? (
          <div
            className={classnames('aspect-ratio', {
              'video--fullscreen': isFullScreen,
            })}
            ref={playerContainer}
          >
            <div className="aspect-ratio__wrap">
              <div className="aspect-ratio__wrap__wrap">
                <div
                  className={classnames('video-text__video', {
                    'video--ready': isReady,
                    'video--playing': isPlaying,
                    'video--started': isStarted,
                    'video--fullscreen': isFullScreen,
                    'video--hover': isHover,
                  })}
                >
                  <div className="video__player" ref={videoPlayer}>
                    {slice.primary.image ? (
                      <div className="video__player__cover">
                        <img
                          loading="lazy"
                          {...generateImageProps(slice.primary.image, {
                            width: 1000,
                            sizes: '(min-width: 769px) 100vw, 650px',
                          })}
                        />
                      </div>
                    ) : null}

                    <div className="video-text__video__wrap">
                      {mountEmbed && (
                        <DynamicReactPlayer
                          className="video__player__embed"
                          ref={player}
                          url={videoUrl}
                          onStart={handleReady}
                          playing={isPlaying}
                          loop
                          controls={false}
                          muted={isMuted}
                          width="100%"
                          height={`300%`}
                          config={{
                            youtube: {
                              preload: true,
                              embedOptions: {
                                host: 'https://www.youtube-nocookie.com',
                              },
                              playerVars: {
                                playsinline: 1,
                                modestbranding: 1,
                                showinfo: 0,
                                loop: 1,
                                playlist: youtubeId,
                              },
                            },
                          }}
                        />
                      )}

                      <div className="video__control">
                        <button
                          className="video__control__play-pause"
                          onClick={(e) => handlePlayPause(e)}
                          aria-label={isPlaying ? 'Pause' : 'Play'}
                          tabIndex={isStarted ? null : -1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="video__player__blur" />

                  <button
                    className="video__fullscreen"
                    onClick={handleFullScreen}
                  >
                    <div className="video__fullscreen__img">
                      <img
                        loading="lazy"
                        src="/icons/fullscreen-icon.png"
                        srcSet="/icons/fullscreen-icon.svg"
                        alt="Plein écran"
                        draggable={false}
                      />
                    </div>
                    <span className="visually-hidden">Plein écran</span>
                  </button>

                  <div
                    className="video__wrapper"
                    onClick={
                      isStarted && isPlaying
                        ? (event) => {
                            handlePlayPause(event);
                          }
                        : null
                    }
                  >
                    <div className="video__wrap">
                      <div className="video__play">
                        <button
                          onClick={
                            isStarted
                              ? (event) => {
                                  handlePlayPause(event);
                                }
                              : handleStart
                          }
                          tabIndex={isStarted ? -1 : null}
                          onMouseOver={() => setIsHover(true)}
                          onMouseOut={() => setIsHover(false)}
                        >
                          <div className="video__play__img">
                            <img
                              loading="lazy"
                              src="/icons/play1.png"
                              srcSet="/icons/play1.svg"
                              alt="Play"
                              draggable={false}
                            />
                          </div>
                          <span className="visually-hidden">Play</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="video__legend">
          <PrismicRichText field={slice.primary.legend} />
        </div>
      </MaxWidth>
    </div>
  );
};

export default Video;
