import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

import { generateImageProps, isImage } from '../../helpers/image';
import { isLink } from '../../helpers/is-link.js';

import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';
import { BatmanIcon } from '../../components/Icons';
import classNames from 'classnames';

const CallToAction = ({ slice }) => (
  <div
    id={slice.primary.anchor || null}
    className={classNames('cta', {
      'cta--no-title': !prismicH.asText(slice.primary.title),
      'cta--has-background': isImage(slice.primary.backgroundImage),
    })}
  >
    <div className="cta__gradient" />

    {isImage(slice.primary.backgroundImage) ? (
      <div className="cta__background">
        <img
          {...generateImageProps(slice.primary.backgroundImage, {
            width: 1000,
          })}
          draggable="false"
        />

        <div className="cta__background__overlay" />
      </div>
    ) : null}

    <MaxWidth>
      <div className="cta__container">
        {slice.primary.icon ? (
          <div className="cta__logo">
            <BatmanIcon />
          </div>
        ) : null}
        {prismicH.asText(slice.primary.title) ? (
          <div className="cta__title">
            <PrismicRichText field={slice.primary.title} />
          </div>
        ) : null}
        {prismicH.asText(slice.primary.description) ? (
          <div className="cta__description">
            <PrismicRichText field={slice.primary.description} />
          </div>
        ) : null}
        {isLink(slice.primary.buttonLink) &&
        prismicH.asText(slice.primary.buttonLinkText) ? (
          <div className="cta__button">
            <Button
              field={slice.primary.buttonLink}
              theme={slice.primary.theme}
            >
              {slice.primary.buttonLinkText ? (
                <PrismicRichText field={slice.primary.buttonLinkText} />
              ) : null}
            </Button>
          </div>
        ) : null}
      </div>
    </MaxWidth>
  </div>
);

export default CallToAction;
