import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { MaxWidth } from '../../components/MaxWidth';
import { generateImageProps } from '../../helpers/image.js';
import { Button } from '../../components/Button';

const GoldTicket = ({ slice }) => {
  return (
    <div className="gold-ticket">
      <MaxWidth>
        <div className="gold-ticket__container">
          <div className="gold-ticket__image">
            <img
              {...generateImageProps(slice.primary.image, {
                width: 1200,
                sizes: '600px',
              })}
            />
          </div>
          <div className="gold-ticket__content">
            <div className="gold-ticket__title">
              {slice.primary.title ? (
                <PrismicRichText field={slice.primary.title} />
              ) : null}
            </div>
            <div className="gold-ticket__paragraph">
              {slice.primary.content ? (
                <PrismicRichText field={slice.primary.content} />
              ) : null}
            </div>
            {slice.primary.buttonLinkText ? (
              <Button field={slice.primary.buttonLink} theme="primary">
                {slice.primary.buttonLinkText ? (
                  <PrismicRichText field={slice.primary.buttonLinkText} />
                ) : null}
              </Button>
            ) : null}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default GoldTicket;
