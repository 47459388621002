import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import screenfull from 'screenfull';
import dynamic from 'next/dynamic';

import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';
import { RightArrowRounded } from '../../components/Icons';

import { generateImageProps } from '../../helpers/image';
import { STATIC_VIDEO_MAP } from '../../helpers/static-video';

const DynamicReactPlayer = dynamic(
  () => import('react-player').then((mod) => mod.default),
  {
    loading: () => <p></p>,
    ssr: false,
  }
);

const VideoText = ({ slice }) => {
  const [mountEmbed, setMountEmbed] = useState(false);

  const staticVideoSrc = STATIC_VIDEO_MAP[slice.primary.videoStatic];

  const youtubeUrl = slice.primary.video?.embed_url;
  const youtubeId =
    youtubeUrl?.match?.(/[?&]v=([^&]+)/)?.[1] ||
    youtubeUrl?.match?.(/youtu\.be\/([^&]+)/)?.[1] ||
    null;

  const staticVideo = useRef(null);
  const playerContainer = useRef(null);
  const player = useRef(null);
  const videoPlayer = useRef(null);

  const [isReady, setIsReady] = useState(staticVideoSrc ? true : false);
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(screenfull.isFullscreen);
  const [playerInstance, setPlayerInstance] = useState(null);

  const [isHover, setIsHover] = useState(false);

  if (screenfull.isEnabled) {
    screenfull.on('change', () => {
      setIsFullScreen(screenfull.isFullscreen);
    });
  }

  const handleFullScreen = () => {
    if (player.current || staticVideo.current) {
      screenfull.toggle(playerContainer.current);

      if (!isStarted) {
        handleStart();
      }
    }
  };

  const handlePlayPause = (event = null, toggle = null) => {
    if (event?.detail === 2) {
      handleFullScreen();
    }

    if (staticVideo.current) {
      if (staticVideo.current.paused) {
        staticVideo.current.play();
        setIsPlaying(true);
      } else {
        staticVideo.current.pause();
        setIsPlaying(false);
      }
    }

    if (player.current) {
      setIsPlaying((current) => (!toggle ? !current : toggle));
    }
  };

  const handleStart = () => {
    if (staticVideo.current) {
      if (isStarted) {
        handlePlayPause();
      } else {
        staticVideo.current.currentTime = 0;

        setIsStarted(true);
        setIsPlaying(true);
        setIsMuted(false);
      }
    }

    if (playerInstance) {
      if (isStarted) {
        handlePlayPause();
      } else {
        playerInstance.seekTo(0);

        setIsMuted(false);
        setIsPlaying(true);

        setIsStarted(true);
      }
    }
  };

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);

  return (
    <div
      className={classnames('video-text', {
        'video-text--reverse': slice.primary.reverse,
      })}
    >
      <div className="video-text__gradient" />
      <MaxWidth>
        <div className="video-text__container">
          {Object.keys(slice.primary.video).length > 0 ? (
            <div
              className={classnames('aspect-ratio', {
                'video--fullscreen': isFullScreen,
              })}
              ref={playerContainer}
            >
              <div className="aspect-ratio__wrap">
                <div className="aspect-ratio__wrap__wrap">
                  <div
                    className={classnames('video-text__video', {
                      'video-text--ready': isReady,
                      'video-text--playing': isPlaying,
                      'video-text--started': isStarted,
                      'video-text--fullscreen': isFullScreen,
                      'video-text--hover': isHover,
                    })}
                  >
                    <div className="video-text__player" ref={videoPlayer}>
                      {slice.primary.miniature ? (
                        <div className="video-text__player__cover">
                          <img
                            {...generateImageProps(slice.primary.miniature, {
                              sizes: '(min-width: 769px) 400px, 85vw',
                            })}
                          />
                        </div>
                      ) : null}

                      <div className="video-text__video__wrap">
                        {staticVideoSrc ? (
                          <video
                            ref={staticVideo}
                            className="video-text__player__static"
                            src={staticVideoSrc}
                            loop
                            muted={isMuted}
                            autoPlay
                            onClick={handlePlayPause}
                            playsInline
                          />
                        ) : mountEmbed ? (
                          <DynamicReactPlayer
                            className="video-text__player__embed"
                            ref={player}
                            url={youtubeUrl}
                            onStart={() => setIsReady(true)}
                            onReady={(player) => {
                              setPlayerInstance(player);
                              setIsReady(true);
                            }}
                            playing={isPlaying}
                            loop
                            controls={false}
                            muted={isMuted}
                            width="100%"
                            height="300%"
                            config={{
                              youtube: {
                                preload: true,
                                embedOptions: {
                                  host: 'https://www.youtube-nocookie.com',
                                },
                                playerVars: {
                                  playsinline: 1,
                                  modestbranding: 1,
                                  showinfo: 0,
                                  loop: 1,
                                  playlist: youtubeId,
                                },
                              },
                            }}
                          />
                        ) : null}

                        <div className="video-text__control">
                          <button
                            className="video-text__control__play-pause"
                            onClick={(e) => handlePlayPause(e)}
                            aria-label={isPlaying ? 'Pause' : 'Play'}
                            tabIndex={isStarted ? null : -1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="video-text__player__blur" />

                    <button
                      className="video-text__fullscreen"
                      onClick={handleFullScreen}
                    >
                      <div className="video-text__fullscreen__img">
                        <img
                          src="/icons/fullscreen-icon.png"
                          srcSet="/icons/fullscreen-icon.svg"
                          alt="Plein écran"
                          draggable={false}
                        />
                      </div>
                      <span className="visually-hidden">Plein écran</span>
                    </button>

                    <div
                      className="video-text__wrapper"
                      onClick={
                        isStarted && isPlaying
                          ? (event) => {
                              handlePlayPause(event);
                            }
                          : null
                      }
                    >
                      <div className="video-text__wrap">
                        <div className="video-text__play">
                          <button
                            onClick={
                              isStarted
                                ? (event) => {
                                    handlePlayPause(event);
                                  }
                                : handleStart
                            }
                            tabIndex={isStarted ? -1 : null}
                            onMouseOver={() => setIsHover(true)}
                            onMouseOut={() => setIsHover(false)}
                          >
                            <div className="video-text__play__img">
                              <img
                                src="/icons/play1.png"
                                srcSet="/icons/play1.svg"
                                alt="Play"
                                draggable={false}
                              />
                            </div>
                            <span className="visually-hidden">Play</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="video-text__image">
              <img
                {...generateImageProps(slice.primary.miniature, {
                  sizes: '(min-width: 769px) 400px, 85vw',
                })}
              />
            </div>
          )}

          <div className="video-text__content">
            {slice.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classnames('video-text__content__item', {
                    'video-text__content__item--reverse': slice.primary.reverse,
                  })}
                >
                  {item.data ? (
                    <div className="video-text__content__item__data">
                      <PrismicRichText field={item.data} />
                    </div>
                  ) : null}

                  {item.description ? (
                    <div className="video-text__content__item__description">
                      <PrismicRichText field={item.description} />
                    </div>
                  ) : null}

                  {item.link && item.link.link_type != 'Any' ? (
                    <Link field={item.link}>
                      <RightArrowRounded />
                    </Link>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default VideoText;
