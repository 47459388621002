import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';

import { isImage, generateImageProps } from '../../helpers/image';
import { Heading } from '../../components/Heading';
import { Link } from '../../components/Link';
import { MaxWidth } from '../../components/MaxWidth';

import { isLink } from '../../helpers/is-link';
import * as prismicH from '@prismicio/helpers';

const SharingMoments = ({ slice }) => {
  return (
    <div id={slice.primary.anchor || null} className="sharing-moments">
      <div className="sharing-moments__transition-left">
        <picture>
          <source
            srcSet="/illustrations/transition-city-left.avif"
            type="image/avif"
          />

          <img
            src="/illustrations/transition-city-left.jpg"
            alt="City"
            draggable={false}
            height="636px"
            width="631px"
            loading="lazy"
          />
        </picture>
      </div>
      <div className="sharing-moments__transition-right">
        <picture>
          <source
            srcSet="/illustrations/transition-city-right.avif"
            type="image/avif"
          />

          <img
            src="/illustrations/transition-city-right.jpg"
            alt="City"
            draggable={false}
            height="636px"
            width="631px"
            loading="lazy"
          />
        </picture>
      </div>

      <MaxWidth>
        <div className="sharing-moments__container">
          <Heading
            title={
              slice.primary.title ? (
                <PrismicRichText field={slice.primary.title} />
              ) : null
            }
          />
          <div className="sharing-moments__description">
            {slice.primary.description ? (
              <PrismicRichText field={slice.primary.description} />
            ) : null}
          </div>

          <div
            className={classnames(
              'sharing-moments__list',
              `sharing-moments__list--${slice.items.length}`,
              {
                'sharing-moments__list--2-lines':
                  slice.primary.twoColumn === true,
              }
            )}
          >
            {slice.items.map((item, index) => {
              const title = item.title ? (
                <div className="sharing-moments__item__title">
                  <p>{prismicH.asText(item.title)}</p>
                </div>
              ) : null;

              const hasLink = isLink(item.buttonLink);

              return (
                <div
                  className={classnames('sharing-moments__item', {
                    'sharing-moments__item--has-link': hasLink,
                    'sharing-moments__item--no-image': !isImage(item.image),
                  })}
                  key={index}
                >
                  <div className="sharing-moments__item__gradient" />

                  {isImage(item.image) ? (
                    <div className="sharing-moments__item__image">
                      <img
                        {...generateImageProps(item.image, {
                          width: 1200,
                          sizes: '600px',
                          lazy: true,
                        })}
                      />
                    </div>
                  ) : null}

                  <div className="sharing-moments__item__content">
                    <div className="sharing-moments__item__content__container">
                      {title ? (
                        hasLink ? (
                          <Link field={item.buttonLink}>{title}</Link>
                        ) : (
                          title
                        )
                      ) : null}

                      {item.description ? (
                        <div className="sharing-moments__item__description">
                          <PrismicRichText field={item.description} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default SharingMoments;
